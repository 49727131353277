export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      // defaultProps: {
      //   IconComponent: ExpandMoreRoundedIcon
      // },

      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.z20
        },
        root: {
          '& .MuiOutlinedInput-root': {
            fontSize: 14,
            padding: 0,
            paddingLeft: 8
          },
          '& .MuiInputLabel-root': {
            fontSize: 14,
            lineHeight: 1,
            marginTop: -3
          },
          '& .MuiInputBase-input': {
            paddingTop: 9,
            paddingBottom: 10
          }
        }
      }
    }
  };
}
