import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useIsMountedRef from '../hooks/useIsMountedRef';

// utils
import { setSession } from '../utils/jwt';
import { postLogin, postSignup, postForgotpassword } from '../server/server';
import {
  setUserEmail,
  setUserName,
  getUserSession,
  setUserSession,
  setGlobalProjectId,
  setCompanyName,
  setIsOwner,
  setUserState,
  setRoleId,
  getUser,
  setUser,
  setCompanyId,
  setUserCountry,
  setUserId,
  setUserCity,
  setCountryCodeIso,
  setProjectExist,
  getAccessToken,
  setAccessToken,
  setJwtToken,
} from '../utilities/utilities';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state) => {
    const user = getUser();

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => {
    const user = getUser();

    return {
      ...state,
      isAuthenticated: false,
      user,
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMountedRef = useIsMountedRef();
  useEffect(() => {
    initialize();
  }, []);
  const initialize = async () => {
    try {
      const accessToken = getAccessToken();

      if (accessToken) {
        if (!getUserSession() || moment().diff(moment(getUserSession()), 'minutes') > 240) {
          localStorage.clear();
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        } else {
          setUserSession(moment());
          setSession(accessToken);
          const user = getUser();

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        }
      } else {
        localStorage.clear();
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  const login = async (email, password, { setErrors, setSubmitting }) => {
    let accessToken = null;
    localStorage.clear();
    await postLogin(email, password).then((res) => {
      if (!res.Message) {
        accessToken = res.token;
        const user = {
          id: res.userData?.id,
          displayName: `${res.userData?.user_first_name} ${res.userData?.user_last_name}`,
          email: res.userData?.user_username,
          password: 'demo1234',
          photoURL: res.userData?.user_profile_img,
          phoneNumber: '+40 777666555',
          country: res.userData?.user_country,
          address: '90210 Broadway Blvd',
          state: 'California',
          city: 'San Francisco',
          zipCode: '94116',
          about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
          role: res.userRole[0].role_name,
          isPublic: true,
        };
        setUser(user);
        localStorage.setItem('i18nextLng', 'en');
        setUserSession(moment());
        if (res?.projectID?.length) {
          setProjectExist(1);
          setGlobalProjectId(res?.projectID[0]?.proj_auto_id);
        } else {
          setProjectExist(0);
        }
        if (res.userCompany[0]) {
          setCompanyId(res.userCompany[0].company_auto_id);
          setCompanyName(res?.userCompany[0].company_name);
        }
        // if (res?.userData) {
        //   setProjectExist(1);
        //   setGlobalProjectId(res?.userData?.last_login_project_id);
        // } else {
        //   setProjectExist(0);
        // }
        setIsOwner(res.userData?.user_is_owner);
        setUserId(res.userData?.id);
        setRoleId(res.userData?.role_id);
        setUserCountry(res?.userData.user_country);
        setUserCity(res?.userData.user_city);
        setUserState(res?.userData.user_state);
        setCountryCodeIso(res?.userData.user_country_iso2);
        // localStorage.setItem('jwtToken', res.token);
        setJwtToken(res.token);
        localStorage.setItem('sd_key_SA', res.userData.super_admin);
        localStorage.setItem('sd_key_SA_Active', res.userData.super_admin_active);
        setUserName(`${res.userData?.user_first_name} ${res.userData?.user_last_name}`);
        setUserEmail(res.userData?.user_username);
        // localStorage.setItem('start_break', false);
        setSession(accessToken);
        setSubmitting(false);
        dispatch({
          type: 'LOGIN',
          payload: {
            user,
          },
        });
      }

      if (res.Message) {
        if (isMountedRef.current) {
          setErrors({
            afterSubmit: res.Message,
          });
          setSubmitting(false);
        }
      }
    });
  };

  const register = async (firstName, lastName, email) => {
    let accessToken = null;
    postSignup(firstName, lastName, email).then((res) => {
      if (!res.Message) {
        accessToken = res.token;
        const user = {
          id: '8864c717-587d-472a-929a-8e5f298024da-0',
          displayName: 'Jaydon Frankie',
          email: 'demo@minimals.cc',
          password: 'demo1234',
          photoURL: '/static/mock-images/avatars/avatar_default.jpg',
          phoneNumber: '+40 777666555',
          country: 'United States',
          address: '90210 Broadway Blvd',
          state: 'California',
          city: 'San Francisco',
          zipCode: '94116',
          about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
          role: 'admin',
          isPublic: true,
        };
        // window.localStorage.setItem('accessToken', accessToken);
        setAccessToken(accessToken);
        dispatch({
          type: 'REGISTER',
          payload: {
            user,
          },
        });
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = async (email) => {
    let response = null;
    postForgotpassword(email).then((res) => {
      response = res;
    });
    return response;
  };

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
