import moment from 'moment';
import { getCookie, formatDate } from '../helpers';
import {
  getUserName,
  getRoleId,
  getUserState,
  setUserState,
  getUserId,
  getUser,
  getCompanyId,
  setUserCountry,
  getUserCity,
  setUserCity,
  setCountryCodeIso,
  getCountryCodeIso,
  getUserSession,
  getJwtToken,
  getGlobalProjectId,
  getJwtTokenForSuperAdmin,
  toISOLocal,
} from '../utilities/utilities';
import { localToUTC } from '../utilities/dates';
//
// const BASE_URL = 'http://167.99.62.73:3003';
// const BASE_URL = 'https://app.tradefsd.com';
// const BASE_URL = 'https://scrumdroid.com:3001';
const BASE_URL = 'https://app.scrumdroid.com';
//
const jwtToken = () =>
  // return localStorage.getItem('jwtToken');
  getJwtToken();
const jwtTokenSuperAdmin = () =>
  // return localStorage.getItem('jwtToken');
  getJwtTokenForSuperAdmin();
function parse(num) {
  return `${`0${Math.floor(num) % 24}`.slice(-2)}:${`${(num % 1) * 60}0`.slice(0, 2)}`;
}

function isAuthentic() {
  let val = false;
  const authed = getCookie('authed');

  if (authed === 'true') {
    val = true;
  } else {
    window.location.href = '/login';
  }

  return val;
}

export const GetUserLocation = async () => {
  setUserCountry(null);
  setUserState(null);
  setUserCity(null);
  const response = await fetch(`https://ipfind.co/me?auth=dbe817fa-2c28-4e15-be56-40ad7baadcff`);
  const data = await response.json();
  if (response.status === 200) {
    setUserCountry(data.country);
    setUserState(data.region);
    setUserCity(data.city);
    setCountryCodeIso(data.country_code);
  } else {
    setUserCountry(null);
    setUserState(null);
    setUserCity(null);
  }
  return data.status === 'success' ? data : null;
};

export const postLogin = async (email, password) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/login`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_username: email,
      user_password: password,
    }),
  });
  const data = await response.json();
  return data;
};

export const postSignup = async (ufn, uln, email) => {
  // if (!isAuthentic()) return;

  const bodyData = {
    user_first_name: ufn,
    user_last_name: uln,
    user_username: email,
  };

  if (!bodyData.user_first_name) {
    delete bodyData.user_first_name;
  }
  if (!bodyData.user_last_name) {
    delete bodyData.user_last_name;
  }

  const url = `${BASE_URL}/signup`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const postTeamInvite = async (
  ufn,
  uln,
  clockIn,
  clockOut,
  email,
  fm,
  rm,
  joiningDate,
  birthDate,
  nationalID,
  probationDate,
  officeHours,
  userPhoneNumber,
  parentCompanyId,
  userChildCompanyId,
  roleId
  // address,
  // userIsActive,
  // isEnabled
) => {
  // if (!isAuthentic()) return;
  const bodyData = {
    user_company_id: parentCompanyId,
    user_first_name: ufn,
    user_last_name: uln,
    office_in_timing: clockIn,
    office_out_timing: clockOut,
    user_username: email,
    functional_manager: fm?.id,
    recruitment_manager: rm?.id,
    joining_date: moment(joiningDate).format('YYYY-MM-DD'),
    user_birth_date: moment(birthDate).format('YYYY-MM-DD'),
    cnic: nationalID,
    probation_period: moment(probationDate).format('YYYY-MM-DD'),
    functional_manager_name: `${fm?.user_first_name} ${fm?.user_last_name}`,
    recruitment_manager_name: `${rm?.user_first_name} ${rm?.user_last_name}`,
    user_working_hours: officeHours,
    user_phone_number: userPhoneNumber,
    role_id: roleId,
    // address: address,
    // user_is_active: userIsActive,
    // is_enabled: isEnabled,
  };
  if (userChildCompanyId) {
    bodyData.userChildCompanyId = userChildCompanyId;
  }

  let url = `${BASE_URL}/signup?logged_user=${Number(getUserId())}`;
  if (userChildCompanyId) {
    url += `&parent_company_id=${parentCompanyId}&user_child_company_id=${userChildCompanyId}`;
  }
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};
export const editEmployeeInformation = async (user, employee, values, fm, rm, flag) => {
  const exFlag = flag ? 1 : 0;
  const url = `${BASE_URL}/editEmployeeInformation`;
  const bodyData = {
    user_id: user,
    employee_id: employee,
    user_first_name: values.firstname,
    user_last_name: values.lastname,
    office_in_timing: values.timeIn,
    office_out_timing: values.timeOut,
    attendance_excluding_flag: exFlag,
    userPhoneNumber: values.phone,
    functional_manager: fm?.id,
    recruitment_manager: rm?.id,
    joining_date: moment(values.joiningDate).format('YYYY-MM-DD'),
    user_birth_date: moment(values.birthDate).format('YYYY-MM-DD'),
    cnic: values.nationalID,
    probation_period: moment(values.probationDate).format('YYYY-MM-DD'),
    user_working_hours: values?.officeHours,
    functional_manager_name: `${fm?.user_first_name} ${fm?.user_last_name}`,
    recruitment_manager_name: `${rm?.user_first_name} ${rm?.user_last_name}`,
    // address: values.address,
    role_id: values.roleId,
    user_is_active: values.userIsActive,
    is_enabled: values.isEnabled,
  };
  // if (Number(getRoleId()) === 3) {
  //   delete bodyData.functional_manager;
  //   delete bodyData.recruitment_manager;
  // }
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const RegisterCompany = async (
  name,
  owner,
  industry,
  address,
  country,
  city,
  inTime,
  outTime,
  description,
  website,
  employees,
  logo,
  lateArrivalThreshold,
  values
) => {
  const dataToSend = {
    user_id: getUserId(),
    company_name: name,
    company_ceo_name: owner,
    industry,
    company_address: address,
    country,
    city,
    office_in_timing: inTime,
    office_out_timing: outTime,
    company_description: description,
    company_url: website,
    num_of_employees: employees,
    company_logo: logo,
    company_auto_id: getCompanyId(),
    late_arrival_threshold: lateArrivalThreshold,
    threshold_for_birthdays_holidays: values.daysThreshold,
  };
  const url = `${BASE_URL}/updateCompanyProfile`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  });
  const data = await response.json();
  return data;
};

export const getCountries = async () => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/getAllCountries`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};

export const getUsersAdmin = async (page, pageSize, search, order, orderBy) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
  const response = await fetch(
    `${BASE_URL}/admingetusers?page=${page}&page_size=${pageSize}&all_users=${2}&search=${search}&${queryparams}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};
export const getOwnersAdmin = async (page, pageSize, search, order, orderBy) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
  const response = await fetch(
    `${BASE_URL}/admingetusers?page=${page}&page_size=${pageSize}&all_company_owners=${3}&search=${search}&${queryparams}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};

export const fetchCities = async (countryId) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/getCitiesByCountry/${countryId}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};
export const postForgotpassword = async (email) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/forgotpassword`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_username: email,
    }),
  });
  const data = await response.json();
  return data;
};
export const changePassword = async (oldPassword, newPassword, confirmPassword) => {
  const url = `${BASE_URL}/updatePassword/${getUserId()}`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    }),
  });
  const data = await response.json();
  return data;
};
export const getIndustries = async () => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/get-industries`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const postEnableuser = async (values) => {
  let user_country = null;
  let user_state = null;
  let user_city = null;
  getCountryCodeIso() != 'null' && (user_country = getCountryCodeIso());
  getUserState() != 'null' && (user_state = getUserState());
  getUserCity() != 'null' && (user_city = getUserCity());

  const val = {
    user_password: values.password,
    user_country,
    user_city,
    user_state,
  };

  const url = `${BASE_URL}/enableuser`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${values.myuser}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(val),
  });
  const data = await response.json();
  return data;
};

export const passwordCheck = async (values) => {
  const url = `${BASE_URL}/passwordCheck`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${values}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const companyTiming = async (values) => {
  const url = `${BASE_URL}/getCompanyOfficeTimings/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getCompanyUsers = async (order, orderBy) => {
  // if (COMPANY_ID !== null) {
  let url = `${BASE_URL}/getCompanyUsers/${getCompanyId()}`;
  if (orderBy && orderBy !== '') url += `?${orderBy}=${order}&`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
  // }
};

export const getTeamList = async (order, orderBy, status, query, page, rows, filterValues) => {
  const values = { ...filterValues };
  let queryparams = '';
  for (const [key, value] of Object.entries(values)) {
    queryparams += `${key}=${value}&`;
  }
  let url = `${BASE_URL}/getCompanyUsers/${getCompanyId()}?`;
  if (queryparams !== '') url += `${queryparams}`;
  if (orderBy && orderBy !== '') url += `${orderBy}=${order}&`;
  if (status !== null) url += `${status}&`;
  if (query !== '') url += `search=${query}&`;
  if (page !== null) url += `page=${page}&`;
  if (rows !== null) url += `page_size=${rows}&`;

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();
  return data;
};

export const getSingleProject = async (id) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/getSingleProject/${id}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getAllProjectCreationData = async () => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/getAllProjectCreationData`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getCompany = async (id) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/getCompanyById/${id}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const postCreateProject = async (values, dummy, users) => {
  const bodyData = {
    proj_auto_id: values.projectId,
    company_id: Number(getCompanyId()),
    user_id: Number(getUserId()),
    proj_name: values.projectname.trim(),
    proj_flag: 1,
    priority_auto_id: values.prioritylabel,
    proj_short_name: values.shortname.trim(),
    des_project: values.projectdescription.trim(),
    project_template_auto_id: values.projecttemplateselect,
    workflow_auto_id: values.workflowselect,
    workflow_bug_auto_id: values.bugworkflowlabel,
    proj_estimated_hr: values.estimatedhours,
    proj_start_date: values.startdate ? formatDate(values.startdate) : '',
    proj_end_date: values.duedate ? formatDate(values.duedate) : '',
    currency_auto_id: values.currencylabel,
    project_status_auto_id: values.projectstatuslabel,
    task_type_auto_id: values.tasktypelabel,
    industry_auto_id: values.industrylabel,
    notify_via_email: dummy,
    // notify_via_email_adminHrOwner: adminHrOwnerDummyArray,
    project_manager_id: values.projectmanagerlabel,
    Users: users,
  };
  if (!bodyData.task_type_auto_id) {
    delete bodyData.task_type_auto_id;
  }
  if (!bodyData.proj_auto_id) {
    delete bodyData.proj_auto_id;
  }
  // if (!bodyData.des_project) {
  //   delete bodyData.des_project;
  // }
  if (!bodyData.project_template_auto_id) {
    delete bodyData.project_template_auto_id;
  }
  if (!bodyData.workflow_auto_id) {
    delete bodyData.workflow_auto_id;
  }
  if (!bodyData.workflow_bug_auto_id) {
    delete bodyData.workflow_bug_auto_id;
  }
  if (!bodyData.proj_estimated_hr) {
    delete bodyData.proj_estimated_hr;
  }
  if (!bodyData.proj_start_date) {
    delete bodyData.proj_start_date;
  }
  if (!bodyData.proj_end_date) {
    delete bodyData.proj_end_date;
  }
  if (!bodyData.currency_auto_id) {
    delete bodyData.currency_auto_id;
  }
  if (!bodyData.project_status_auto_id) {
    delete bodyData.project_status_auto_id;
  }
  if (!bodyData.industry_auto_id) {
    delete bodyData.industry_auto_id;
  }
  if (!bodyData.project_manager_id) {
    delete bodyData.project_manager_id;
  }
  if (!bodyData.priority_auto_id) {
    delete bodyData.priority_auto_id;
  }
  const response = await fetch(`${BASE_URL}/createProject`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const postCreateTask = async (
  values,
  projectname,
  priorityname,
  taskgroupname,
  tasktypename,
  statusname,
  assignname,
  imagesData,
  estHours,
  dataInfo,
  recurring,
  emailTags
) => {
  const user = getUser();
  const bodyData = {
    proj_task_auto_id: values?.taskautoid,
    user_id: parseInt(getUserId(), 10),
    company_id: parseInt(getCompanyId(), 10),
    proj_auto_id: values?.projectlabel,
    assign_to: values?.assigntolabel,
    assign_name: assignname !== null ? `${assignname[0]?.user_full_name}` : 'Nobody',
    user_first_name: dataInfo?.user_first_name || getUserName().split(' ')[0],
    user_last_name: dataInfo?.user_last_name || getUserName().split(' ')[1],
    proj_name: projectname[0]?.proj_name,
    task_title: values?.tasktitle,
    task_type_auto_id: values?.tasktypelabel,
    task_type_name: tasktypename[0]?.task_type_name,
    priority_auto_id: values?.prioritylabel,
    priority_name: priorityname[0]?.priority_name,
    task_group_auto_id: values?.taskGroupLabel,
    task_group: taskgroupname[0]?.task_group,
    task_detail: values?.description,
    start_date: values?.startdate ? moment(values?.startdate).format('YYYY-MM-DD') : '',
    due_date: values?.duedate ? moment(values?.duedate).format('YYYY-MM-DD') : '',
    task_est_hr: estHours || '',
    task_related_to_auto_id: 1,
    task_related_name: 'Derived from',
    task_status_auto_id: values?.status || 1,
    status_name: statusname?.length ? statusname[0]?.status_name : 'New',
    notify_via_email: emailTags,
    attachment: imagesData,
    taskActivity: [
      {
        company_id: Number(getCompanyId()),
      },
    ],
  };
  if (recurring) {
    recurring[0]?.weekly === 'weekly'
      ? (bodyData.recurring_weekly = recurring)
      : recurring[0]?.daily === 'daily'
      ? (bodyData.recurring = recurring)
      : delete bodyData.recurring;
  }

  // if (!bodyData.task_est_hr) {
  //   delete bodyData.task_est_hr;
  // }
  if (!bodyData.due_date) {
    delete bodyData.due_date;
  }
  if (!bodyData.start_date) {
    delete bodyData.start_date;
  }
  if (bodyData.assign_name === 'Nobody') {
    bodyData.assign_to = 0;
  }
  if (bodyData.proj_task_auto_id === '') {
    delete bodyData.proj_task_auto_id;
  }
  // if (bodyData.assign_to === null) {
  //   bodyData.assign_name = 'Nobody';
  // }
  // if (!bodyData.assign_name) {
  //   delete bodyData.assign_name;
  // }
  if (!bodyData.createddate) {
    delete bodyData.createddate;
  }
  if (bodyData.attachment.length === 0 && !bodyData.proj_task_auto_id) {
    delete bodyData.attachment;
  }
  if (bodyData.taskActivity.length === 0) {
    delete bodyData.taskActivity;
  }
  const url = `${BASE_URL}/createTask`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  // const data = await response;
  const data = await response.json();
  return data;
};

export const postTaskWithRecurrimg = async (values, recurring) => {
  const recurringObj =
    recurring?.pattern === 'weekly'
      ? {
          schedule_flag: 1,
          weekly: recurring?.pattern,
          monday: recurring.monday ? 1 : 0,
          tuesday: recurring.tuesday ? 1 : 0,
          wednesday: recurring.wednesday ? 1 : 0,
          thursday: recurring.thursday ? 1 : 0,
          friday: recurring.friday ? 1 : 0,
          saturday: recurring.saturday ? 1 : 0,
          sunday: recurring.sunday ? 1 : 0,
          start_date: recurring?.startdate ? moment(recurring?.startdate).format('YYYY-MM-DD') : '',
          end_date: recurring?.enddate ? moment(recurring?.enddate).format('YYYY-MM-DD') : '',
        }
      : {
          schedule_flag: 1,
          weekly: recurring?.pattern,
          start_date: recurring?.startdate ? moment(recurring?.startdate).format('YYYY-MM-DD') : '',
          end_date: recurring?.enddate ? moment(recurring?.enddate).format('YYYY-MM-DD') : '',
        };
  const bodyData = {
    proj_task_auto_id: values?.proj_task_auto_id,
    user_id: Number(getUserId()),
    company_id: Number(getCompanyId()),
    proj_auto_id: values?.proj_auto_id,
    assign_to: values?.assign_to,
    assign_name: values?.assign_name !== null ? `${values?.assign_name}` : 'Nobody',
    user_first_name: values?.user_first_name || getUserName().split(' ')[0],
    user_last_name: values?.user_last_name || getUserName().split(' ')[1],
    proj_name: values.proj_name,
    task_title: values?.task_title,
    task_type_auto_id: values?.task_type_auto_id,
    task_type_name: values?.task_type_name,
    priority_auto_id: values?.priority_auto_id,
    priority_name: values?.priority_name,
    task_group_auto_id: values?.task_group_auto_id,
    task_group: values?.task_group,
    task_detail: values?.task_detail,
    start_date: values?.startdate ? moment(values?.startdate).format('YYYY-MM-DD') : '',
    due_date: values?.duedate ? moment(values?.duedate).format('YYYY-MM-DD') : '',
    task_est_hr: values?.task_est_hr,
    task_related_to_auto_id: 1,
    task_related_name: 'Derived from',
    task_status_auto_id: values?.task_status_auto_id || 1,
    status_name: values?.length ? values?.status_name : 'New',
    notify_via_email: values?.notify_via_email,
    attachment: values?.TaskAttachment,
    taskActivity: [
      {
        company_id: Number(getCompanyId()),
      },
    ],
  };

  recurring?.pattern === 'weekly'
    ? (bodyData.recurring_weekly = [recurringObj])
    : (bodyData.recurring = [recurringObj]);

  if (!bodyData.task_est_hr) {
    delete bodyData.task_est_hr;
  }
  if (!bodyData.due_date) {
    delete bodyData.due_date;
  }
  if (!bodyData.start_date) {
    delete bodyData.start_date;
  }
  if (bodyData.assign_name === 'Nobody') {
    bodyData.assign_to = 0;
  }
  // if (bodyData.assign_to === null) {
  //   bodyData.assign_name = 'Nobody';
  // }
  // if (!bodyData.assign_name) {
  //   delete bodyData.assign_name;
  // }
  if (!bodyData.createddate) {
    delete bodyData.createddate;
  }
  if (bodyData.attachment.length === 0 && !bodyData.proj_task_auto_id) {
    delete bodyData.attachment;
  }
  if (bodyData.taskActivity.length === 0) {
    delete bodyData.taskActivity;
  }

  const url = `${BASE_URL}/createTask`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  // const data = await response;
  const data = await response.json();
  return data;
};

export const getAllCompanyUsers = async (page, pageSize, search) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/getCompanyUsers/${getCompanyId()}?page=${page}&page_size=${pageSize}&search=${search}`;
  // const changes = projIds ? url + `?proj_auto_id=${projIds}` : null;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getAllCompanyUsersChangeTask = async (page, pageSize, search) => {
  const url = `${BASE_URL}/getCompanyUsers/${getCompanyId()}?page=${page}&page_size=${pageSize}&search=${search}`;
  // const changes = projIds ? url + `?proj_auto_id=${projIds}` : null;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getProjectbyUser = async (
  filteredValues,
  page,
  rowsPerPage,
  search,
  order,
  orderBy,
  dash,
  csv = '',
  query_params
) => {
  const values = { ...filteredValues };
  let queryparams = '';
  for (const [key, value] of Object.entries(values)) {
    queryparams += `${key}=${value}&`;
  }
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;

  let url = `${BASE_URL}/getProjectbyUser/${getUserId()}/company/${getCompanyId()}?${queryparams}`;

  if (page) {
    url += `page=${page}&`;
  }
  if (rowsPerPage) {
    url += `page_size=${rowsPerPage}&`;
  }
  if (search) {
    url += `projectName=${search}&`;
  }
  if (csv) {
    url += `csv=${csv}&`;
  }
  if (query_params) {
    url += `columns=${query_params}&`;
  }

  // Check if project_status is already included in the URL
  if (!url.includes('project_status') && dash !== 1) {
    url += 'project_status=1&';
  }

  // Add 'data=1' only if 'csv' is not set to '1'
  if (csv !== '1' && !url.includes('data') && !url.includes('pdf=1')) {
    url += 'data=1&';
  }

  // Remove the trailing '&' from the URL if there is one
  if (url.endsWith('&')) {
    url = url.slice(0, -1);
  }

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();
  return data;
};

export const getAllTaskCreationData = async (projId) => {
  const url = `${BASE_URL}/getAllTaskCreationData/${getUserId()}/company/${getCompanyId()}/project/${projId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getAllTaskProject = async (
  filteredValues,
  search,
  page,
  rowsPerPage,
  order,
  orderBy,
  kanbanView
  // assignTo
) => {
  const values = { ...filteredValues };
  values.system_date = moment().format('YYYY-MM-DD');

  // Remove undefined, empty string, or date with no value parameters
  for (const key in values) {
    if (values[key] === undefined || values[key] === '' || (key.includes('date') && !values[key])) {
      delete values[key];
    }
  }

  // Format date parameters
  if (values.task_created_start_date) {
    values.task_created_start_date = moment(values.task_created_start_date).format('YYYY-MM-DD');
  }
  if (values.task_created_end_date) {
    values.task_created_end_date = moment(values.task_created_end_date).format('YYYY-MM-DD');
  }
  if (values.last_udpate_date_start) {
    values.last_udpate_date_start = moment(values.last_udpate_date_start).format('YYYY-MM-DD');
  }
  if (values.last_udpate_date_end) {
    values.last_udpate_date_end = moment(values.last_udpate_date_end).format('YYYY-MM-DD');
  }

  // Set proj_auto_id if not present
  if (!values?.proj_auto_id) {
    values.proj_auto_id = Number(getGlobalProjectId());
  }

  // Remove unnecessary fields
  delete values.proj_field;
  delete values.user_id_field;
  delete values.assign_to_field;

  // Handle assign_to field
  if (values?.assign_to && values?.assign_to[0] === '0') {
    values.assign_to = 0;
  }

  // Add search, page, and rowsPerPage if present
  if (search !== '' && search !== undefined) {
    const containsOnlyNumericAndSpecial = /^[0-9!@#$%^&*()_+[\]{};':"\\|,.<>/?`~-]*$/.test(search);
    if (containsOnlyNumericAndSpecial) {
      search = search.replace(/[^0-9]/g, '');
      console.log(search);
    }
    if (Number(search)) {
      values.refrenceID = search;
    } else {
      values.task_title = search;
    }
  } else {
    delete values.refrenceID;
    delete values.task_title;
  }

  if (page !== undefined) {
    values.page = page;
  }

  if (rowsPerPage !== undefined) {
    values.page_size = rowsPerPage;
  }

  // Construct query parameters
  let queryparams = '';
  for (const [key, value] of Object.entries(values)) {
    queryparams += `${key}=${value}&`;
  }

  // Add orderBy and kanbanView if present
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  if (kanbanView && kanbanView === 1) queryparams += `kanban=${kanbanView}&`;

  // Remove the trailing '&' if present
  queryparams = queryparams.replace(/&$/, '');

  const url = `${BASE_URL}/getAllTaskProject/${getCompanyId()}?${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getUserTasks = async (filteredValues, search, page, rowsPerPage, order, orderBy, kanbanView, assignTo) => {
  const values = { ...filteredValues };
  values.system_date = moment().format('YYYY-MM-DD');

  if (page) values.page = page;
  if (rowsPerPage) values.page_size = rowsPerPage;
  if (search !== '' && search !== undefined) {
    delete values.page;
    if (Number(search)) {
      values.refrenceID = search;
    } else values.task_title = search;
  } else {
    delete values.refrenceID;
    delete values.task_title;
  }

  const formatDate = (date) => (date ? moment(date).format('YYYY-MM-DD') : '');

  values.task_created_start_date = formatDate(values.task_created_start_date);
  values.task_created_end_date = formatDate(values.task_created_end_date);
  values.last_udpate_date_start = formatDate(values.last_udpate_date_start);
  values.last_udpate_date_end = formatDate(values.last_udpate_date_end);

  if (!values?.proj_auto_id) {
    values.proj_auto_id = Number(getGlobalProjectId());
  }

  if (!values?.assign_to) {
    values.assign_to = Number(getUserId());
  }

  delete values.proj_field;
  delete values.user_id_field;
  delete values.assign_to_field;

  let queryparams = '';
  for (const [key, value] of Object.entries(values)) {
    if (value !== undefined && value !== '') {
      queryparams += `${key}=${value}&`;
    }
  }

  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  if (kanbanView && kanbanView === 1) queryparams += `kanban=${kanbanView}&`;

  const checkurl = `${BASE_URL}/getAllTaskProject/${getCompanyId()}?${queryparams}`;
  const url = checkurl;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getAllTaskKanban = async (filteredValues, search, page, rowsPerPage, order, orderBy, kanbanView) => {
  if (page) filteredValues.page = page;
  if (rowsPerPage) filteredValues.page_size = rowsPerPage;

  if (search !== '' && search !== undefined) {
    delete filteredValues.page;
    if (Number(search)) {
      filteredValues.refrenceID = search;
    } else filteredValues.task_title = search;
  } else {
    delete filteredValues.refrenceID;
    delete filteredValues.task_title;
  }

  const formatDate = (date) => (date ? moment(date).format('YYYY-MM-DD') : '');

  filteredValues.task_created_start_date = formatDate(filteredValues.task_created_start_date);
  filteredValues.task_created_end_date = formatDate(filteredValues.task_created_end_date);
  filteredValues.last_udpate_date_start = formatDate(filteredValues.last_udpate_date_start);
  filteredValues.last_udpate_date_end = formatDate(filteredValues.last_udpate_date_end);

  if (!filteredValues?.proj_auto_id) {
    filteredValues.proj_auto_id = Number(getGlobalProjectId());
  }

  delete filteredValues.proj_field;
  delete filteredValues.user_id_field;
  delete filteredValues.assign_to_field;

  if (filteredValues?.assign_to && filteredValues?.assign_to[0] === '0') {
    filteredValues.assign_to = 0;
  }

  filteredValues.system_date = moment().format('YYYY-MM-DD');

  let queryparams = '';
  for (const [key, value] of Object.entries(filteredValues)) {
    if (value !== undefined && value !== '') {
      queryparams += `${key}=${value}&`;
    }
  }

  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  if (kanbanView && kanbanView === 1) queryparams += `kanban=${kanbanView}&`;

  const checkurl = `${BASE_URL}/getAllTaskKanban/${getCompanyId()}?${queryparams}`;
  const url = checkurl;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getUserKanban = async (
  filteredValues,
  search,
  page,
  rowsPerPage,
  order,
  orderBy,
  kanbanView,
  assignTo
) => {
  if (page) filteredValues.page = page;
  if (rowsPerPage) filteredValues.page_size = rowsPerPage;

  if (search !== '' && search !== undefined) {
    delete filteredValues.page;
    if (Number(search)) {
      filteredValues.refrenceID = search;
    } else filteredValues.task_title = search;
  } else {
    delete filteredValues.refrenceID;
    delete filteredValues.task_title;
  }

  const formatDate = (date) => (date ? moment(date).format('YYYY-MM-DD') : '');

  filteredValues.task_created_start_date = formatDate(filteredValues.task_created_start_date);
  filteredValues.task_created_end_date = formatDate(filteredValues.task_created_end_date);
  filteredValues.last_udpate_date_start = formatDate(filteredValues.last_udpate_date_start);
  filteredValues.last_udpate_date_end = formatDate(filteredValues.last_udpate_date_end);

  if (!filteredValues?.proj_auto_id) {
    filteredValues.proj_auto_id = Number(getGlobalProjectId());
  }

  if (!filteredValues?.assign_to) {
    filteredValues.assign_to = Number(getUserId());
  }

  delete filteredValues.proj_field;
  delete filteredValues.user_id_field;
  delete filteredValues.assign_to_field;

  let queryparams = '';
  for (const [key, value] of Object.entries(filteredValues)) {
    if (value !== undefined && value !== '') {
      queryparams += `${key}=${value}&`;
    }
  }

  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  if (kanbanView && kanbanView === 1) queryparams += `kanban=${kanbanView}&`;

  const checkurl = `${BASE_URL}/getAllTaskKanban/${getCompanyId()}?${queryparams}`;
  const url = checkurl;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const companyCheck = async (name) => {
  const skillObj = {
    company_name: name,
  };
  const response = await fetch(`${BASE_URL}/uniqueCompany`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(skillObj),
  });
  const data = await response.json();
  return data;
};

export const getSingleTask = async (comp, user, project, task) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/getSingleTaskProjectByUserId/${project}/company/${comp}/task/${user}/${task}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const deleteProject = async (project) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/deleteProjectByAdmin`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      company_id: Number(getCompanyId()),
      // user_id: Number(getUserId()),
      proj_auto_id: project,
      role_id: Number(getRoleId()),
    }),
  });
  const data = await response.json();
  return data;
};

export const deleteTaskByUser = async (id) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/deleteTaskByUser`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      company_id: parseInt(getCompanyId(), 10),
      user_id: parseInt(getUserId(), 10),
      proj_task_auto_id: id,
    }),
  });
  const data = await response.json();
  return data;
};

export const deleteTaskByAdmin = async (id) => {
  // if (!isAuthentic()) return;

  const url = `${BASE_URL}/deleteTaskByAdmin`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      company_id: parseInt(getCompanyId(), 10),
      user_id: parseInt(getUserId(), 10),
      role_id: parseInt(getRoleId(), 10),
      proj_task_auto_id: id,
    }),
  });
  const data = await response.json();
  return data;
};

export const submitProfile = async (values, userId) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/users/${userId}/profile`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  const data = await response.json();
  return data;
};

export const getUserProfileInfo = async (userId) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  const response = await fetch(`${BASE_URL}/getUserProfileInfo/${userId}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};

export const postLeaveTeam = async (id) => {
  // if (!isAuthentic()) return;
  // const jwtToken = localStorage.getItem('jwtToken');
  const url = `${BASE_URL}/leaveTeam`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: id,
    }),
  });
  const data = await response.json();
  return data;
};

export const postAssignUserRole = async (user_id, role_id) => {
  // if (!isAuthentic()) return;
  // const jwtToken = localStorage.getItem('jwtToken');
  const url = `${BASE_URL}/assignUserRole`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: user_id,
      role_id,
    }),
  });
  const data = await response.json();
  return data;
};

export const postTaskAssignChange = async (user, task) => {
  const userId = user?.id ? user?.id : user?.user_id;
  const userName = user?.user_first_name ? `${user?.user_first_name} ${user?.user_last_name}` : user?.user_full_name;
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task?.proj_name,
    proj_short_name: task?.proj_short_name,
    task_title: task?.task_title,
    refrenceID: task?.refrenceID,
    task_type_name: task?.task_type_name,
    status_name: task?.status_name,
    priority_name: task?.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    assign_to: userId ? userId : 0,
    assign_name: userName ? userName : 'Nobody',
    notify_via_email: task?.notify_via_email,
  };
  const response = await fetch(`${BASE_URL}/taskAssignChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const postTaskPriorityChange = async (id, priority, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task.proj_name,
    proj_short_name: task.proj_short_name,
    task_title: task.task_title,
    refrenceID: task.refrenceID,
    task_type_name: task.task_type_name,
    status_name: task.status_name,
    priority_auto_id: priority.priority_auto_id,
    priority_name: priority.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    notify_via_email: task.notify_via_email,
  };
  const response = await fetch(`${BASE_URL}/taskPriorityChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const postTaskStatusChange = async (id, status, taskId) => {
  const bodyData = {
    proj_task_auto_id: taskId?.toString(),
    status_name: status?.status_name ? status?.status_name : status,
    task_status_auto_id: id,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
  };
  const response = await fetch(`${BASE_URL}/taskStatusChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const postTaskDueDateChange = async (value, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task.proj_name,
    proj_short_name: task.proj_short_name,
    task_title: task.task_title,
    refrenceID: task.refrenceID,
    task_type_name: task.task_type_name,
    status_name: task.status_name,
    priority_name: task.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    due_date: value ? moment(value).format('YYYY-MM-DD') : '',
    notify_via_email: task.notify_via_email,
  };
  const response = await fetch(`${BASE_URL}/taskDueDateChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const postTaskStartDateChange = async (value, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task.proj_name,
    proj_short_name: task.proj_short_name,
    task_title: task.task_title,
    refrenceID: task.refrenceID,
    task_type_name: task.task_type_name,
    status_name: task.status_name,
    priority_name: task.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    start_date: value ? moment(value).format('YYYY-MM-DD') : '',
    notify_via_email: task.notify_via_email,
  };
  const response = await fetch(`${BASE_URL}/taskStartDateChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const postTaskTitleChange = async (title, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task.proj_name,
    proj_short_name: task.proj_short_name,
    task_title: title,
    refrenceID: task.refrenceID,
    status_name: task.status_name,
    priority_name: task.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    notify_via_email: task.notify_via_email,
  };
  const response = await fetch(`${BASE_URL}/taskTitleChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const postTaskDesChange = async (des, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task.proj_name,
    proj_short_name: task.proj_short_name,
    task_detail: des,
    task_title: task.task_title,
    refrenceID: task.refrenceID,
    status_name: task.status_name,
    priority_name: task.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    notify_via_email: task.notify_via_email,
  };
  const response = await fetch(`${BASE_URL}/taskDescriptionChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const postTaskEstHrChange = async (hr, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task.proj_name,
    proj_short_name: task.proj_short_name,
    task_detail: task.task_title,
    task_title: task.task_title,
    refrenceID: task.refrenceID,
    task_est_hr: hr,
    status_name: task.status_name,
    priority_name: task.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    notify_via_email: task.notify_via_email,
  };
  const response = await fetch(`${BASE_URL}/taskEstHoursChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const postTaskGroupChange = async (id, name, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task?.proj_name,
    proj_short_name: task?.proj_short_name,
    task_group_auto_id: id,
    task_group: name,
    refrenceID: task?.refrenceID,
    status_name: task?.status_name,
    priority_name: task?.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    notify_via_email: task?.notify_via_email,
  };
  const response = await fetch(`${BASE_URL}/taskGroupChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const postTaskTypeChange = async (id, name, task) => {
  const bodyData = {
    proj_task_auto_id: task?.proj_task_auto_id,
    company_id: task?.company_id,
    proj_auto_id: task?.proj_auto_id,
    proj_name: task?.proj_name,
    proj_short_name: task?.proj_short_name,
    task_title: task?.task_title,
    refrenceID: task?.refrenceID,
    task_type_auto_id: id,
    task_type_name: name,
    status_name: task?.status_name,
    priority_name: task?.priority_name,
    user_id: Number(getUserId()),
    user_first_name: getUserName().split(' ')[0],
    user_last_name: getUserName().split(' ')[1],
    notify_via_email: task?.notify_via_email,
  };
  const response = await fetch(`${BASE_URL}/taskTypeChange`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const getUserByProject = async (project) => {
  const url = `${BASE_URL}/getUsersByProject/${project}/Company/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getEstHrByUser = async (userId, project) => {
  const url = `${BASE_URL}/getEstHrByUser/${userId}/project/${project}/`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getSpentHourByUser = async (userId, project) => {
  const url = `${BASE_URL}/getSpentHourByUser/${userId}/project/${project}/`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const postInsertTaskTime = async (values, info, date) => {
  // if (!isAuthentic()) return;
  const bodyData = {
    user_id: Number(getUserId()),
    company_id: info.company_id,
    proj_auto_id: info.proj_auto_id,
    proj_task_auto_id: info.proj_task_auto_id,
    proj_name: info.proj_name,
    task_title: info.task_title,
    task_details: info.task_detail,
    task_created_date: moment(info.task_created_date).format('YYYY-MM-DD'),
    start_date: info.start_date ? moment(info.start_date).format('YYYY-MM-DD') : '',
    due_date: info.due_date ? moment(info.due_date).format('YYYY-MM-DD') : '',
    task_spent_hour_date: values.enddate
      ? moment(values.enddate).format('YYYY-MM-DD')
      : moment(date).format('YYYY-MM-DD'),
    task_spent_hour: values.timespent ? values.timespent : values,
    task_timesheet_auto_id: info.task_timesheet_auto_id ? info.task_timesheet_auto_id : '',
  };

  if (bodyData.task_timesheet_auto_id === '') {
    delete bodyData.task_timesheet_auto_id;
  }
  if (bodyData.start_date === '') {
    delete bodyData.start_date;
  }
  if (bodyData.due_date === '') {
    delete bodyData.due_date;
  }
  const url = `${BASE_URL}/insertTaskTime`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};
export const postInsertTaskTimeWeekly = async (arrayOfObjs) => {
  const url = `${BASE_URL}/insertTaskTimeWeekly`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(arrayOfObjs),
  });
  const data = await response.json();
  return data;
};

export const getSingleTaskLogs = async (taskData) => {
  // const url = `${BASE_URL}/getSingleTaskLogs/${taskData.proj_task_auto_id}/task/${taskData.proj_auto_id}/project/${taskData.assign_to}`;
  const url = `${BASE_URL}/getSingleTaskLogs/${taskData.proj_task_auto_id}/task/${taskData.proj_auto_id}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getProjectEstHr = async (project) => {
  const url = `${BASE_URL}/getProjectEstHr/${project}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getProjectSpenttHr = async (project) => {
  const url = `${BASE_URL}/getProjectSpenttHr/${project}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getWeeklyTask = async (start, end, user, search, projects, emptyFilter, selectedTaskType) => {
  try {
    const companyId = getCompanyId();
    const url = `${BASE_URL}/getWeeklyTask/${companyId}/startdate/${formatDate(start)}/endDate/${formatDate(
      end
    )}?assign_to=${user}&proj_auto_id=${projects}&filterEmpty=${emptyFilter}&task_type=${selectedTaskType}&searchOnDate=${search}`;
    const response = await fetch(url, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return { error: 'An error occurred.' };
  }
};

export const getWeeklyTaskView = async (start, end, user, search, projects, emptyFilter, selectedTaskType) => {
  try {
    const companyId = getCompanyId();
    const url = `${BASE_URL}/getWeeklyTask/${companyId}/startdate/${formatDate(start)}/endDate/${formatDate(
      end
    )}?assign_to=${user}&proj_auto_id=${projects}&filterEmpty=1&task_type=${selectedTaskType}&searchOnDate=${search}`;
    const response = await fetch(url, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return { error: 'An error occurred.' };
  }
};

export const getMissingTask = async (
  size,
  page,
  userid,
  projectManager,
  search,
  selectedHours,
  order,
  orderBy,
  dateRange,
  csv = '',
  pdfParams
) => {
  const coulumnParams = pdfParams || '';
  const today = new Date();
  const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000); // Calculate yesterday
  const last30Days = new Date(yesterday.getTime() - 30 * 24 * 60 * 60 * 1000); // Use yesterday's date for the starting point

  const startDate = formatDate(last30Days);
  const endDate = formatDate(yesterday); // Use yesterday's date instead of today

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const startDateRange = dateRange[0] ? new Date(dateRange[0]) : last30Days;
  const endDateRange = dateRange[1] ? new Date(new Date(dateRange[1])) : yesterday;

  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
  if (csv === '') queryparams += 'data=1&';
  const url = `${BASE_URL}/missingTaskTimesheet/${Number(
    getCompanyId()
  )}?page_size=${size}&page=${page}&assign_to=${userid.toString()}&project_manager=${projectManager.toString()}&givenHours=${selectedHours}&search=${search}&${queryparams}&startDate=${formatDate(
    startDateRange
  )}&endDate=${formatDate(endDateRange)}${csv}&columns=${coulumnParams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getMissingTaskUser = async (
  size,
  page,
  userid,
  projectManager,
  search,
  selectedHours,
  order,
  orderBy,
  dateRange,
  assignTo,
  csv = '',
  pdfParams
) => {
  const coulumnParams = pdfParams || '';
  const today = new Date();
  const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000); // Calculate yesterday
  const last30Days = new Date(yesterday.getTime() - 30 * 24 * 60 * 60 * 1000); // Use yesterday's date for the starting point

  const startDate = formatDate(last30Days);
  const endDate = formatDate(yesterday); // Use yesterday's date instead of today

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const startDateRange = dateRange[0] ? new Date(dateRange[0]) : last30Days;
  const endDateRange = dateRange[1] ? new Date(new Date(dateRange[1])) : yesterday;

  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
  if (csv === '') queryparams += 'data=1&';
  const url = `${BASE_URL}/missingTaskTimesheet/${Number(
    getCompanyId()
  )}?page_size=${size}&page=${page}&assign_to=${assignTo}&project_manager=${projectManager.toString()}&givenHours=${selectedHours}&search=${search}&${queryparams}&startDate=${formatDate(
    startDateRange
  )}&endDate=${formatDate(endDateRange)}${csv}&columns=${coulumnParams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getListViewTask = async (
  users,
  projects,
  taskStatus,
  taskType,
  dates,
  rowsPerPage,
  page,
  query,
  order,
  orderBy
) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const url = `${BASE_URL}/getViewList/${Number(
    getCompanyId()
  )}?assign_to=${users.toString()}&proj_auto_id=${projects.toString()}&status_name=${taskStatus.toString()}&task_type=${taskType.toString()}&search=${query}&task_created_date=${
    dates[0] ? toISOLocal(dates[0]) : ''
  }&due_date=${dates[1] ? toISOLocal(dates[1]) : ''}&page=${page}&page_size=${rowsPerPage}&${queryparams}`;

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const deleteTaskTimeLog = async (info) => {
  const url = `${BASE_URL}/deleteSingleTaskLogs`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      proj_task_auto_id: info.proj_task_auto_id,
      proj_auto_id: info.proj_auto_id,
      user_id: info.user_id,
      task_timesheet_auto_id: info.task_timesheet_auto_id,
    }),
  });
  const data = await response.json();
  return data;
};

export const postInsertTimeLogs = async (info, values, spentHours) => {
  const url = `${BASE_URL}/insertTimeLogs`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: 1,
      company_id: 1,
      time_clock_status: 'signing in',
      away_auto_id: 1,
      away_time: 'Away for 5 minutes',
    }),
  });
  const data = await response.json();
  return data;
};

export const getAway = async (project) => {
  const url = `${BASE_URL}/getAway`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Sign in and away status_id's and status strings for dropdown
export const getSignAway = async () => {
  const url = `${BASE_URL}/getSignAway`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Get time logs by company
export const getTimeLogByCompany = async () => {
  const url = `${BASE_URL}/getTimeLogs/${getUserId()}/Company/${getCompanyId()}`;
  // const url = `${BASE_URL}/getTimeLogs/1/Company/1`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Get recent swipes by user
export const getRecentSwipes = async () => {
  const systemDateTimeUTC = moment().utc().format('YYYY-MM-DD');
  // const url = `${BASE_URL}/getTimeLogs/${getUserId()}/Company/${getCompanyId()}`;
  const url = `${BASE_URL}/getRecentSwipes/${getUserId()}/company/${getCompanyId()}?system_date_time_utc=${systemDateTimeUTC}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Get recent swipes by user
export const getSignInOut = async () => {
  const url = `${BASE_URL}/getClockInByUser/${getUserId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

// Get recent breaks by user
export const getBreaks = async () => {
  const url = `${BASE_URL}/getAwayBackByUser/${getUserId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const postSignInOut = async (
  status,
  channel,
  late,
  lateTimeValue,
  reason,
  dateTime,
  formattedDateTimedata,
  userTimeZone,
  lateTimeInMinutes,
  lateSuggestedTimeValue
) => {
  const url = `${BASE_URL}/insertAttendance`;
  const suggestedSignInTime =
    status === 'Signing in' && late === 1 ? (reason ? null : lateTimeValue.toLocaleString()) : null;
  const dt = new Date();
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: Number(`${getUserId()}`),
      company_id: Number(`${getCompanyId()}`),
      attendance_name: status,
      channel:
        channel === 'office'
          ? status === 'Signing in'
            ? `${status} from ${channel}`
            : `${status}`
          : status === 'Signing in'
          ? `${status} ${channel}`
          : `${status}`,
      channel_flag: channel === 'office' ? 1 : 0,
      suggested_sign_in_time_utc:
        status === 'Signing in' && late === 1 ? (reason ? null : localToUTC(suggestedSignInTime)) : null, // Use the UTC time here
      suggested_attendance_flag: status === 'Signing in' && late === 1 ? (reason ? 0 : 1) : 0,
      reason: status === 'Signing in' ? reason || null : '',
      system_date_time: localToUTC(dt),
      system_date_time_utc: localToUTC(dt),
      system_date_time_utc_out: status === 'Signing out' ? localToUTC(dt) : null,
      system_date_time_local: status === 'Signing in' ? localToUTC(dt) : null,
      user_is_late: status === 'Signing in' ? lateTimeInMinutes : null,
      user_is_late_suggested: status === 'Signing in' && late === 1 ? (reason ? null : lateSuggestedTimeValue) : null,
    }),
  });
  const data = await response.json();
  return data;
};

export const postAwayBack = async (breakReason, dateTime) => {
  const url = `${BASE_URL}/insertBreak`;

  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: Number(`${getUserId()}`),
      company_id: Number(`${getCompanyId()}`),
      break_reason: breakReason,
      break_time_utc: localToUTC(dateTime), // Include the UTC time in the request
      system_date_time: localToUTC(dateTime),
    }),
  });
  const data = await response.json();
  return data;
};

export const getBreakStatus = async () => {
  const systemDateTimeUTC = moment().utc().format('YYYY-MM-DD');
  const url = `${BASE_URL}/getBreakStatus?system_date_time_utc=${systemDateTimeUTC}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getAbsentEmployee = async () => {
  const systemDateTimeUTC = moment().utc().format('YYYY-MM-DD');
  const url = `${BASE_URL}/getAbsentEmployee/${getCompanyId()}?system_date_time_utc=${systemDateTimeUTC}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getAllEmployeeStatus = async (page, pageSize, search) => {
  const systemDateTimeUTC = moment().utc().format('YYYY-MM-DD');
  const url = `${BASE_URL}/getAllEmployeeStatus/${getCompanyId()}?page=${page}&page_size=${pageSize}&search=${search}&system_date_time_utc=${systemDateTimeUTC}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getEmployeeLastStatus = async () => {
  const systemDateTimeUTC = moment().utc().format('YYYY-MM-DD');
  const url = `${BASE_URL}/getEmployeeLastStatus/${getUserId()}/company/${getCompanyId()}?system_date_time_utc=${systemDateTimeUTC}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getSignOutUser = async (page, rowsPerPage) => {
  const systemDateTimeUTC = moment().utc().format('YYYY-MM-DD');
  const url = `${BASE_URL}/getSignOutUser?company_id=${getCompanyId()}&page=${page}&page_size=${rowsPerPage}&sortByFirstName=asc&system_date_time_utc=${systemDateTimeUTC}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getAllAwayEmployee = async () => {
  const systemDateTimeUTC = moment().utc().format('YYYY-MM-DD');
  const url = `${BASE_URL}/getAllAwayEmployee/${getCompanyId()}?system_date_time_utc=${systemDateTimeUTC}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getSignInMemberStatus = async () => {
  const systemDateTimeUTC = moment().utc().format('YYYY-MM-DD');
  // const url = `${BASE_URL}/getSignInMemberStatus/${getCompanyId()}?page=${page}&page_size=${pageSize}`;
  const url = `${BASE_URL}/getSignInMemberStatus/${getCompanyId()}?system_date_time_utc=${systemDateTimeUTC}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getEmployeesOfficeData = async (page, rowsPerPage, order, orderBy, search, filterStatus) => {
  let url = `${BASE_URL}/getEmployeesOfficeData/${getCompanyId()}?page=${page}&page_size=${rowsPerPage}&search=${search}&`;
  if (filterStatus !== null && filterStatus !== '') {
    url += `${filterStatus}&`;
  }
  let queryparams = '';
  if (orderBy && orderBy !== '') {
    queryparams += `${orderBy}=${order}&`;
  }
  url += queryparams;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getTaskStatusesbyUser = async () => {
  const url = `${BASE_URL}/getProjectTaskStatusesbyUser/${getUserId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getTaskStatusesbyUserAndProj = async (userId, projId) => {
  const url = `${BASE_URL}/getProjectTaskStatusesbyUserAndProjectId/${userId}/project/${projId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getAttendanceReport = async (
  size,
  page,
  userid,
  search,
  dateRange,
  late,
  filterStatus,
  filterStatusLocation,
  userTimeZone,
  csv = '',
  query_params
) => {
  const selectedLate = late || '';
  const selectedLocation = filterStatusLocation || '';
  const selected_query_params = query_params || '';
  const userZoneTime = userTimeZone || '';
  let url = `${BASE_URL}/getAttendanceReport/company/${getCompanyId()}?user_time_zone=${userZoneTime}&page_size=${size}&page=${page}&user_id=${userid}&search=${search}&startDate=${
    dateRange[0] ? toISOLocal(dateRange[0]) : ''
  }&dueDate=${
    dateRange[1] ? toISOLocal(dateRange[1]) : ''
  }&late=${selectedLate}&location=${selectedLocation}${csv}&columns=${selected_query_params}`;

  if (filterStatus !== null) {
    url += `&${filterStatus}`;
  }
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const getAttendanceReportCSV = async (
  size,
  page,
  userid,
  search,
  dateRange,
  late,
  csv = '&csv=1',
  query_params
) => {
  const selectedLate = late || '';
  const selected_query_params = query_params || '';
  const url = `${BASE_URL}/getAttendanceReportCSV/company/${getCompanyId()}?page_size=${size}&page=${page}&user_id=${userid}&search=${search}&startDate=${
    dateRange[0] ? toISOLocal(dateRange[0]) : ''
  }&dueDate=${
    dateRange[1] ? toISOLocal(dateRange[1]) : ''
  }&late=${selectedLate}${csv}&columns=${selected_query_params}`;

  try {
    const response = await fetch(url, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
        'Content-Type': 'text/csv', // Set content-type to text/csv for CSV response
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch CSV data');
    }
    const csvData = await response.text();
    return csvData;
  } catch (error) {
    // Handle errors here
    console.error('Error fetching CSV data:', error);
    throw error;
  }
};

export const getallpayroll = async (size, page, userid, search, order, orderBy, dateRange, filterStatus, csv = '') => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const url = `${BASE_URL}/getAllPayroll/?page_size=${size}&page=${page}&user_id=${userid}&search=${search}&${queryparams}&effective_date_start_date=${
    dateRange[0] ? toISOLocal(dateRange[0]) : ''
  }&effective_date_end_date=${dateRange[1] ? toISOLocal(dateRange[1]) : ''}&${filterStatus}${csv}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const generateTaskListPdf = async (filter, csv = '', query_params, search) => {
  const values = {
    priority_name: filter.priority_name ? filter.priority_name : '',
    status_name: filter.status_name ? filter.status_name : '',
    task_type_name: filter.task_type_name ? filter.task_type_name.toString() : '',
    user_id: filter.user_id ? filter.user_id.toString() : '',
    task_overdue: filter.task_overdue ? filter.task_overdue : '',
    task_title: filter.task_title ? filter.task_title : '',
    assign_to: filter.assign_to ? filter.assign_to.toString() : '',
    proj_auto_id: filter.proj_auto_id ? filter.proj_auto_id.toString() : '',
    task_today: filter.task_today ? filter.task_today : '',
    task_created_start_date: filter.task_created_start_date
      ? moment(filter.task_created_start_date).format('YYYY-MM-DD')
      : '',
    task_created_end_date: filter.task_created_end_date
      ? moment(filter.task_created_end_date).format('YYYY-MM-DD')
      : '',
    task_created_date: filter.task_created_date ? moment(filter.task_created_date).format('YYYY-MM-DD') : '',
    due_date: filter.due_date ? moment(filter.due_date).format('YYYY-MM-DD') : '',
    last_udpate_date_start: filter.last_udpate_date_start
      ? moment(filter.last_udpate_date_start).format('YYYY-MM-DD')
      : '',
    last_udpate_date_end: filter.last_udpate_date_end ? moment(filter.last_udpate_date_end).format('YYYY-MM-DD') : '',
  };

  // Remove undefined or empty string parameters
  for (const key in values) {
    if (values[key] === undefined || values[key] === '' || (key.includes('date') && !values[key])) {
      delete values[key];
    }
  }

  // Construct query parameters
  let queryparams = '';
  for (const [key, value] of Object.entries(values)) {
    queryparams += `${key}=${value}&`;
  }

  // Append csv and query_params only if they are not empty
  if (csv !== '') {
    queryparams += `${csv}`;
  }

  if (query_params !== '') {
    queryparams += `&columns=${query_params}`;
  }

  // Append search parameter if not empty
  if (search !== '') {
    queryparams += `&task_title=${search}`;
  }

  const url = `${BASE_URL}/generateTaskListPdf/${getCompanyId()}?${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const generateProjectListPdf = async (filter, csv = '', query_params) => {
  const url = `${BASE_URL}/getProjectbyUser/${getUserId()}/company/${getCompanyId()}?proj_auto_id=${
    filter.proj_auto_id ? filter.proj_auto_id.toString() : ''
  }&proj_short_name=${filter.proj_short_name}&project_manager_name=${filter.project_manager_name}&proj_estimated_hr=${
    filter.proj_estimated_hr
  }&priority_auto_id=${filter.priority_auto_id ? filter.priority_auto_id.toString() : ''}&project_status_auto_id=${
    filter.project_status_auto_id ? filter.project_status_auto_id.toString() : ''
  }&exp_Date=${filter.exp_Date ? filter.exp_Date : ''}${csv}&columns=${query_params}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const timeSheetListLogs = async (
  users,
  projects,
  taskStatus,
  taskType,
  dates,
  csv = '',
  query_params,
  query
) => {
  const filterDates =
    dates && dates[0] && dates[1] ? `&task_created_date=${dates[0]}&task_updated_date=${dates[1]}` : '';
  const url = `${BASE_URL}/timeSheetViewListLogs/${getCompanyId()}?assign_to=${
    users ? users.toString() : ''
  }&status_name=${taskStatus ? taskStatus.toString() : ''}&task_type=${
    taskType ? taskType.toString() : ''
  }&search=${query}&proj_auto_id=${projects ? projects.toString() : ''}&task_created_date=${
    dates[0] ? toISOLocal(dates[0]) : ''
  }&due_date=${dates[1] ? toISOLocal(dates[1]) : ''}${csv}&columns=${query_params}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const timeSheetViewListLogs = async (
  users,
  projects,
  taskStatus,
  taskType,
  startDate = '',
  endDate = '',
  csv = '',
  pdfParams,
  searchLog
  // dates
) => {
  const filterDates =
    startDate && endDate
      ? `&task_created_date=${moment(startDate).format('YYYY-MM-DD')}&task_updated_date=${moment(endDate).format(
          'YYYY-MM-DD'
        )}`
      : '';
  const url = `${BASE_URL}/timeSheetViewListLogs/${getCompanyId()}?assign_to=${
    users ? users.toString() : ''
  }&status_name=${taskStatus ? taskStatus.toString() : ''}&task_type=${
    taskType ? taskType.toString() : ''
  }&proj_auto_id=${
    projects ? projects.toString() : ''
  }${filterDates}${csv}&columns=${pdfParams}&searchOnDate=${searchLog}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getWeeklyTaskPdf = async (start, end, user) => {
  const url = `${BASE_URL}/getWeeklyTaskPdf/${getCompanyId()}?assign_to=${
    user || Number(getUserId())
  }&task_created_date=${start || ''}&task_updated_date=${end || ''}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const postTaskGroup = async (values) => {
  const url = `${BASE_URL}/taskGroupGeneric`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: Number(getUserId()),
      company_id: Number(getCompanyId()),
      default_flag: 1,
      task_group_generic_flag: 1,
      task_group: values.taskGrouptitle,
      proj_auto_id: Number(getGlobalProjectId()),
      // description: values.taskGroupDes
    }),
  });
  const data = await response.json();
  return data;
};

export const postTaskType = async (name) => {
  const url = `${BASE_URL}/taskTypeDynamic`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: Number(getUserId()),
      company_id: Number(getCompanyId()),
      flag: 1,
      task_type_flag: 1,
      task_type_name: name,
      // description: values.taskGroupDes
    }),
  });
  const data = await response.json();
  return data;
};

export const getTaskGroupGeneric = async () => {
  const url = `${BASE_URL}/getTaskGroupGeneric/${getCompanyId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getTaskActivity = async (userId, taskId) => {
  const url = `${BASE_URL}/getTaskActivity/${userId}/task/${taskId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getTaskComments = async (compId, projId, taskId) => {
  const url = `${BASE_URL}/getTaskComments/${compId}/company/${projId}/task/${taskId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const postTaskComment = async (comments, compId, userId, projId, taskId) => {
  const url = `${BASE_URL}/taskComment`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: Number(userId),
      company_id: Number(compId),
      proj_auto_id: Number(projId),
      proj_task_auto_id: Number(taskId),
      task_comment: comments,
      user_first_name: getUserName().split(' ')[0],
      user_last_name: getUserName().split(' ')[1],
      notify_via_email: 'mudassar.tufail@phenologix.com',
    }),
  });
  const data = await response.json();
  return data;
};

export const getTasksView = async (userId, compId, projId) => {
  const url = `${BASE_URL}/getMytaskView/${userId}/company/${compId}/project/${projId}?page=1&page_size=25`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getOverdueTasksView = async (userId, compId, projId) => {
  const url = `${BASE_URL}/getMyOverduetaskView/${userId}/company/${compId}/project/${projId}?page=1&page_size=25`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getGeneratePdf = async () => {
  const url = `${BASE_URL}/generatePdf`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: Number(`${getUserId()}`),
    }),
  });
  const data = await response.json();
  return data;
};

export const deleteTaskGroup = async (id, name) => {
  const url = `${BASE_URL}/deleteTaskGroupDynamic`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      task_group_auto_id: id,
      company_id: Number(`${getCompanyId()}`),
      // group_id: 1,
      // group_name: 'Story',
      role_id: Number(`${getRoleId()}`),
    }),
  });
  const data = await response.json();
  return data;
};

export const deleteTaskType = async (id, name) => {
  const url = `${BASE_URL}/deleteTaskTypeDynamic`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      task_type_auto_id: id,
      company_id: Number(`${getCompanyId()}`),
      // type_id: 1,
      // type_name: 'Story',
      role_id: Number(`${getRoleId()}`),
    }),
  });
  const data = await response.json();
  return data;
};

// export const postSaveFilter = async (name, projIds, userIds, taskTypeIds, priorityIds, statusIds, date) => {
export const postSaveFilter = async (name, filteredValues) => {
  const formatDate = (date) => (date ? moment(date).format('YYYY-MM-DD') : '');
  const bodyDate = {
    user_id: Number(`${getUserId()}`),
    company_id: Number(`${getCompanyId()}`),
    proj_auto_id: filteredValues.proj_auto_id ? filteredValues.proj_auto_id.toString() : '',
    resource: filteredValues.assign_to ? filteredValues.assign_to.toString() : '',
    task_status_auto_id: filteredValues.status_name ? filteredValues.status_name.toString() : '',
    priority_auto_id: filteredValues.priority_name ? filteredValues.priority_name.toString() : '',
    task_type_auto_id: filteredValues.task_type_name ? filteredValues.task_type_name.toString() : '',
    task_group_auto_id: filteredValues.task_group_auto_id ? filteredValues.task_group_auto_id.toString() : '',
    filter_name: name,
    created_date: filteredValues.task_created_date ? filteredValues.task_created_date : '',
    // due_date: filteredValues.due_date ? filteredValues.due_date : '',
    // task_group_auto_id: '1,5,3,4',
    task_created_user_id: filteredValues.user_id ? filteredValues.user_id.toString() : '',
    // task_over_due: filteredValues.task_overdue === 'Overdue' ? filteredValues.task_overdue : '',
    // task_today: filteredValues.task_overdue === 'today' ? filteredValues.task_overdue : '',
    // created_by: filteredValues.user_id ? filteredValues.user_id.toString() : '',
    // task_created_start_date: formatDate(filteredValues.task_created_start_date)
    //   ? formatDate(filteredValues.task_created_start_date)
    //   : '',
    // task_created_end_date: formatDate(filteredValues.task_created_end_date)
    //   ? formatDate(filteredValues.task_created_end_date)
    //   : '',
    // last_udpate_date_start: formatDate(filteredValues.last_udpate_date_start)
    //   ? formatDate(filteredValues.last_udpate_date_start)
    //   : '',
    // last_udpate_date_end: formatDate(filteredValues.last_udpate_date_end)
    //   ? formatDate(filteredValues.last_udpate_date_end)
    //   : '',
    // task_overdue: filteredValues.task_overdue ? filteredValues.task_overdue : '',
    // task_custom_range: '2022-09-28 10:10:30,2022-09-28 10:10:30'
  };
  if (bodyDate.proj_auto_id === '') delete bodyDate.proj_auto_id;
  if (bodyDate.resource === '') delete bodyDate.resource;
  if (bodyDate.task_status_auto_id === '') delete bodyDate.task_status_auto_id;
  if (bodyDate.priority_auto_id === '') delete bodyDate.priority_auto_id;
  if (bodyDate.task_type_auto_id === '') delete bodyDate.task_type_auto_id;
  if (bodyDate.task_group_auto_id === '') delete bodyDate.task_group_auto_id;
  if (bodyDate.created_date === '') delete bodyDate.created_date;
  if (bodyDate.task_created_user_id === '') delete bodyDate.task_created_user_id;
  if (bodyDate.task_over_due === '') delete bodyDate.task_over_due;
  if (bodyDate.task_today === '') delete bodyDate.task_today;
  if (bodyDate.task_created_start_date === '') delete bodyDate.task_created_start_date;
  if (bodyDate.task_created_end_date === '') delete bodyDate.task_created_end_date;
  if (bodyDate.last_udpate_date_start === '') delete bodyDate.last_udpate_date_start;
  if (bodyDate.last_udpate_date_end === '') delete bodyDate.last_udpate_date_end;
  if (bodyDate.created_by === '') delete bodyDate.created_by;
  if (bodyDate.due_date === '') delete bodyDate.due_date;
  if (bodyDate.task_overdue === '') delete bodyDate.task_overdue;

  const url = `${BASE_URL}/savedFilter`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

export const getSavedFilter = async () => {
  const url = `${BASE_URL}/getSavedFilter/${Number(`${getUserId()}`)}/Company/${Number(getCompanyId())}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getKanbanNewTask = async (filteredValues, search, page, rowsPerPage) => {
  if (page) filteredValues.page = page + 1;
  if (rowsPerPage) filteredValues.page_size = rowsPerPage;
  if (search !== '' && search !== undefined) {
    delete filteredValues.page;
    if (Number(search)) {
      filteredValues.refrenceID = search;
    } else filteredValues.task_title = search;
  } else {
    delete filteredValues.refrenceID;
    delete filteredValues.task_title;
  }

  if (!filteredValues?.proj_auto_id) {
    filteredValues.proj_auto_id = Number(getGlobalProjectId());
  }
  if (filteredValues.proj_field) {
    delete filteredValues.proj_field;
  }
  if (filteredValues.user_id_field) {
    delete filteredValues.user_id_field;
  }
  if (filteredValues.assign_to_field) {
    delete filteredValues.assign_to_field;
  }
  let queryparams = '';
  for (const [key, value] of Object.entries(filteredValues)) {
    queryparams += `${key}=${value}&`;
  }
  const checkurl = `${BASE_URL}/getKanbanNewTask/${getCompanyId()}?${queryparams}`;
  const url = checkurl;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getKanbanInprogress = async (filteredValues, search, page, rowsPerPage) => {
  if (page) filteredValues.page = page + 1;
  if (rowsPerPage) filteredValues.page_size = rowsPerPage;
  if (search !== '' && search !== undefined) {
    delete filteredValues.page;
    if (Number(search)) {
      filteredValues.refrenceID = search;
    } else filteredValues.task_title = search;
  } else {
    delete filteredValues.refrenceID;
    delete filteredValues.task_title;
  }

  if (!filteredValues?.proj_auto_id) {
    filteredValues.proj_auto_id = Number(getGlobalProjectId());
  }
  if (filteredValues.proj_field) {
    delete filteredValues.proj_field;
  }
  if (filteredValues.user_id_field) {
    delete filteredValues.user_id_field;
  }
  if (filteredValues.assign_to_field) {
    delete filteredValues.assign_to_field;
  }
  let queryparams = '';
  for (const [key, value] of Object.entries(filteredValues)) {
    queryparams += `${key}=${value}&`;
  }
  // if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  // if (kanbanView && kanbanView === 1) queryparams += `kanban=${kanbanView}&`;
  const checkurl = `${BASE_URL}/getKanbanInprogress/${getCompanyId()}?${queryparams}`;
  const url = checkurl;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getKanbanResolved = async (filteredValues, search, page, rowsPerPage) => {
  if (page) filteredValues.page = page + 1;
  if (rowsPerPage) filteredValues.page_size = rowsPerPage;
  if (search !== '' && search !== undefined) {
    delete filteredValues.page;
    if (Number(search)) {
      filteredValues.refrenceID = search;
    } else filteredValues.task_title = search;
  } else {
    delete filteredValues.refrenceID;
    delete filteredValues.task_title;
  }

  if (!filteredValues?.proj_auto_id) {
    filteredValues.proj_auto_id = Number(getGlobalProjectId());
  }
  if (filteredValues.proj_field) {
    delete filteredValues.proj_field;
  }
  if (filteredValues.user_id_field) {
    delete filteredValues.user_id_field;
  }
  if (filteredValues.assign_to_field) {
    delete filteredValues.assign_to_field;
  }
  let queryparams = '';
  for (const [key, value] of Object.entries(filteredValues)) {
    queryparams += `${key}=${value}&`;
  }
  // if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  // if (kanbanView && kanbanView === 1) queryparams += `kanban=${kanbanView}&`;

  const checkurl = `${BASE_URL}/getKanbanResolved/${getCompanyId()}?${queryparams}`;
  const url = checkurl;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getKanbanClosed = async (filteredValues, search, page, rowsPerPage) => {
  if (page) filteredValues.page = page + 1;
  if (rowsPerPage) filteredValues.page_size = rowsPerPage;
  if (search !== '' && search !== undefined) {
    delete filteredValues.page;
    if (Number(search)) {
      filteredValues.refrenceID = search;
    } else filteredValues.task_title = search;
  } else {
    delete filteredValues.refrenceID;
    delete filteredValues.task_title;
  }

  if (!filteredValues?.proj_auto_id) {
    filteredValues.proj_auto_id = Number(getGlobalProjectId());
  }
  if (filteredValues.proj_field) {
    delete filteredValues.proj_field;
  }
  if (filteredValues.user_id_field) {
    delete filteredValues.user_id_field;
  }
  if (filteredValues.assign_to_field) {
    delete filteredValues.assign_to_field;
  }
  let queryparams = '';
  for (const [key, value] of Object.entries(filteredValues)) {
    queryparams += `${key}=${value}&`;
  }
  // if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  // if (kanbanView && kanbanView === 1) queryparams += `kanban=${kanbanView}&`;
  const checkurl = `${BASE_URL}/getKanbanClosed/${getCompanyId()}?${queryparams}`;
  const url = checkurl;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const projectDashboard = async (compId, projId) => {
  const url = `${BASE_URL}/projectDashboard/companyId/${compId}/projectId/${projId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getTaskActivityLogByProject = async (projId, compId) => {
  const url = `${BASE_URL}/getTaskActivityLogByProject/project/${projId}/company/${compId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getUserProjects = async () => {
  const url = `${BASE_URL}/getUserProjects/company/${getCompanyId()}/user/${getUserId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getTaskTypeStatusPriority = async () => {
  const url = `${BASE_URL}/getTaskTypeStatusPriority`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getUserRole = async () => {
  const url = `${BASE_URL}/userRole/${getUserId()}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getLateEmployeeStatus = async () => {
  const systemDate = moment().format('YYYY-MM-DD');
  const systemDateTimeUTC = moment().utc().format('YYYY-MM-DD');

  const url = `${BASE_URL}/getLateEmployeeStatus/${getCompanyId()}?system_date=${systemDate}&system_date_time_utc=${systemDateTimeUTC}`;

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();
  return data;
};
export const getUsersOnLeave = async () => {
  const systemDateTimeUTC = moment().utc().format('YYYY-MM-DD');
  const url = `${BASE_URL}/getUsersOnLeave?system_date_time_utc=${systemDateTimeUTC}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const postDisableUser = async (usedId, no) => {
  const bodyDate = {
    user_is_active: no,
    id: usedId,
  };

  const url = `${BASE_URL}/disableUser`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

export const deleteSavedFilter = async (id) => {
  const url = `${BASE_URL}/deleteSavedFilter`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      saved_filter_auto_id: id,
      company_id: Number(`${getCompanyId()}`),
      user_id: Number(`${getUserId()}`),
    }),
  });
  const data = await response.json();
  return data;
};

export const postCompanyLeavePlanner = async (info) => {
  const bodyDate = {
    company_id: Number(`${getCompanyId()}`),
    user_id: Number(`${getUserId()}`),
    role_id: Number(`${getRoleId()}`),
    leave_type_name: info?.name,
    leave_type_description: info?.description,
    leave_unit: info?.leaveunit.string,
    day_count: info?.daycount.string,
    notice_day_count: info?.noticedaycount.string,
    paid_leave_flag: info?.paid ? 1 : 0,
    allow_negative_balance_flag: info?.allowNegative ? 1 : 0,
    reason_required_flag: info?.requireReason ? 1 : 0,
    leave_type_active_flag: info?.active ? 1 : 0,
    leave_count: Number(info?.days.string),
  };

  const url = `${BASE_URL}/companyLeavePlanner`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

export const updateCompanyLeavePlanner = async (info) => {
  const bodyDate = {
    company_id: Number(`${getCompanyId()}`),
    user_id: Number(`${getUserId()}`),
    role_id: Number(`${getRoleId()}`),
    comp_leave_planner_id: info?.id,
    leave_type_name: info?.name,
    leave_type_description: info?.description,
    leave_unit: info?.leaveunit.string,
    day_count: info?.daycount.string,
    notice_day_count: info?.noticedaycount.string,
    paid_leave_flag: info?.paid ? 1 : 0,
    allow_negative_balance_flag: info?.allowNegative ? 1 : 0,
    reason_required_flag: info?.requireReason ? 1 : 0,
    leave_type_active_flag: info?.active ? 1 : 0,
    leave_count: Number(info?.days?.string),
  };

  const url = `${BASE_URL}/updateCompanyLeavePlanner`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

export const getCompanyLeavePlan = async (search, page, rowsPerPage, order, orderBy) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') {
    queryparams += `${orderBy}=${order}&`;
  }
  let url = `${BASE_URL}/getCompanyLeavePlan/${getCompanyId()}?page=${page}&page_size=${rowsPerPage}&${queryparams}`;
  if (search) {
    url += `&search=${search}`;
  }
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const deleteCompanyLeavePlanner = async (id) => {
  const url = `${BASE_URL}/deleteCompanyLeavePlannerType`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      comp_leave_planner_id: id,
      company_id: Number(`${getCompanyId()}`),

      flag: 0,
    }),
  });
  const data = await response.json();
  return data;
};

export const getEarnLeaveByUser = async (id, leaveId) => {
  // const info = id ? id : Number(localStorage.getItem('userId'));
  const url = `${BASE_URL}/earnLeaveByUser/${id}/user/${`${getUserSession().slice(0, 4)}`}/year/${leaveId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getAllCompanyLeaves = async (page, size, filterValues, order, orderBy, search, year, filterStatus) => {
  // ?approved=1&pending=0&page=1&page_size=25
  const memberStatus = filterStatus || '';
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  for (const [key, value] of Object.entries(filterValues)) {
    queryparams += `${key}=${value}&`;
  }
  const url = `${BASE_URL}/getAllCompanyLeaves/${Number(
    `${getCompanyId()}`
  )}/company/${year}?page=${page}&page_size=${size}&${queryparams}&search=${search}&${memberStatus}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getLeaveForApprovalsCheck = async () => {
  const url = `${BASE_URL}/getLeaveForApprovals/${new Date().getFullYear()}?page=1&page_size=25&search=`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getLeaveForApprovals = async (page, size, filterValues, search, year, filterStatus, order, orderBy) => {
  const memberStatus = filterStatus || '';
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  for (const [key, value] of Object.entries(filterValues)) {
    queryparams += `${key}=${value}&`;
  }
  const url = `${BASE_URL}/getLeaveForApprovals/${year}?page=${page}&page_size=${size}&${queryparams}&search=${search}&${memberStatus}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const getMyLeaves = async (page, size, order, orderBy, year) => {
  // ?approved=1&pending=0&page=1&page_size=25
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const url = `${BASE_URL}/getAllCompanyLeaves/${Number(`${getCompanyId()}`)}/company/${year}?user_id=${Number(
    `${getUserId()}`
  )}&page=${page}&page_size=${size}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const postLeaveApplyByUser = async (info, values) => {
  const bodyDate = {
    user_id: info?.user_id ? info?.user_id : Number(`${getUserId()}`),
    company_id: Number(`${getCompanyId()}`),
    leave_type_name: values?.leaveType.leave_type_name,
    comp_leave_planner_id: values?.leaveType.comp_leave_planner_id,
    start_date: moment(info?.start_date).format('YYYY-MM-DD'),
    end_date: moment(info?.end_date).format('YYYY-MM-DD'),
    day: info?.days,
    balance: info?.balance,
    // entitlement_id: values?.user?.entitlement_id,
    entitlement_id: info?.entitlement_id,
    session: info?.session_name,
    reason: values?.reason,
    show_reason_flag: values?.showReason ? 1 : 0,
    emergency_flag: values?.emergencyLeave ? 1 : 0,
  };

  const url = `${BASE_URL}/leaveApplyByUser`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

export const postCompanyHoliday = async (info, id, startDate, endDate) => {
  const bodyDate = {
    user_id: Number(`${getUserId()}`),
    company_id: Number(`${getCompanyId()}`),
    title: info?.code,
    description: info?.description,
    // start_date: moment(info?.startdate).format('YYYY-MM-DD'),
    // end_date: moment(info?.enddate).format('YYYY-MM-DD'),
    active: info?.active ? 1 : 0,
    holidays_id: id,
    start_date: moment(startDate).format('YYYY-MM-DD'),
    end_date: moment(endDate).format('YYYY-MM-DD'),
  };

  if (!bodyDate.holidays_id) delete bodyDate.holidays_id;

  const url = `${BASE_URL}/companyHolidays`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

export const getAllCompanyHolidays = async (page, rowsPerPage, order, orderBy, search, year) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') {
    queryparams += `${orderBy}=${order}&`;
  }
  if (year) {
    queryparams += `year=${year}&`;
  }
  let url = `${BASE_URL}/getAllCompanyHolidays/${Number(`${getCompanyId()}`)}?${queryparams}`;
  if (page) {
    url += `page=${page}`;
  }
  if (rowsPerPage) {
    url += `&page_size=${rowsPerPage}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getCompanySchedule = async (startD, endD) => {
  const url = `${BASE_URL}/companySchedule/${Number(`${getCompanyId()}`)}/company/${moment(startD).format(
    'YYYY-MM-DD'
  )}/date/${moment(endD).format('YYYY-MM-DD')}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getUserLeaves = async (year) => {
  const url = `${BASE_URL}/getUserLeaves/${Number(`${getCompanyId()}`)}/company/${year}?user_id=${Number(
    `${getUserId()}`
  )}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const createChildCompany = async (values, indusObj) => {
  const url = `${BASE_URL}/createChildCompany`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      company_email: values.companyEmail,
      display_name: values.displayName,
      company_name: values.companyName,
      company_ceo_name: values.companyOwner,
      industry: values.industry,
      num_of_employees: values.employees,
      country: values.country,
      city: values.city,
      company_address: values.address,
      company_url: values.website,
      company_logo: values.avatarUrl,
      company_description: values.description,
      user_id: Number(`${getUserId()}`),
      industry_id: indusObj?.industry_auto_id,
      parentCompanyId: Number(`${getCompanyId()}`),
      company_is_parent: 0,
    }),
  });
  const data = await response.json();
  return data;
};

export const updateChildCompany = async (values, indusObj, id) => {
  const url = `${BASE_URL}/updateChildCompany/${getCompanyId()}/${id}`;
  const bodyData = {
    company_email: values.companyEmail,
    display_name: values.displayName,
    company_name: values.companyName,
    company_ceo_name: values.companyOwner,
    industry: values.industry,
    num_of_employees: values.employees,
    country: values.country,
    city: values.city,
    company_address: values.address,
    company_url: values.website,
    company_logo: values.avatarUrl,
    company_description: values.description,
    user_id: Number(`${getUserId()}`),
    industry_id: indusObj?.industry_auto_id,
    parentCompanyId: Number(`${getCompanyId()}`),
    company_is_parent: 0,
    company_auto_id: values.companyAutoId,
  };
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const getChildCompany = async (single = true, id) => {
  const childcompany = id ? `&childCompId=${id || ''}` : '';
  let allComp = null;
  if (single) {
    allComp = `&allChildParentCompId=${Number(getCompanyId()) || ''}`;
  } else {
    allComp = `&ParentCompId=${Number(getCompanyId()) || ''}`;
  }
  const url = `${BASE_URL}/getChildCompany?${allComp}${childcompany}`; // for all verified or unverified sub companies only
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getCompanyEarningPolicy = async (searchValue, page, rowsPerPage, order, orderBy) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') {
    queryparams += `${orderBy}=${order}&`;
  }
  let url = `${BASE_URL}/getCompanyEarningPolicy/${Number(
    `${getCompanyId()}`
  )}?page=${page}&page_size=${rowsPerPage}&active_flag=1&search=${searchValue}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getEarningPolicyData = async () => {
  const url = `${BASE_URL}/getEarningPolicyData`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const earningPolicy = async (values) => {
  const bodyData = {
    company_id: Number(getCompanyId()),
    title: values.code,
    description: values.description,
    earning_method_id: values.method.id,
    earning_accrual_period_id: values.period.id,
    earning_earn_at_id: values.earnAt.id,
    active_flag: values.active ? 1 : 0,
    earning_policy_id: values.earningPolicyId,
  };

  if (!bodyData.earning_policy_id) delete bodyData.earning_policy_id;
  const url = `${BASE_URL}/earningPolicy`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });

  const data = await response.json();
  return data;
};

export const getUserEntitlement = async (id) => {
  const url = `${BASE_URL}/getUserEntitlement/${id}/user/${Number(getUserSession().slice(0, 4))}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getCompanyEntitlement = async (
  startDate,
  page,
  rowsPerPage,
  order,
  orderBy,
  search,
  selectedUsers,
  filterStatus
) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  let url = `${BASE_URL}/getCompanyEntitlement/${Number(getCompanyId())}?&earning_start=${startDate?.slice(
    11,
    15
  )}-01-01&valid_until=${startDate?.slice(11, 15)}-12-31&year=${startDate?.slice(
    11,
    15
  )}&page=${page}&page_size=${rowsPerPage}&userId=${selectedUsers}&search=${search}&${queryparams}`;
  if (filterStatus !== null) {
    url += `&${filterStatus}`;
  }
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getEntitlementBalance = async (values) => {
  const bodyData = {
    earning_start: moment(values?.startDate).format('YYYY-MM-DD'),
    entitled: Number(values?.entitlement),
    carried_over: Number(values?.carriedOver),
    year: String(values?.year),
    user_id: values.user.id,
    entitlement_id: values.entitlementId ? values.entitlementId : '',
    // yearSelect: values.yearSelect
  };

  if (bodyData?.entitlement_id === '') delete bodyData?.entitlement_id;

  const url = `${BASE_URL}/getBalanceTakenCalculator`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const userEntitlement = async (values, entitlementStartDate, entitlementEndDate) => {
  const bodyData = {
    company_id: Number(getCompanyId()),
    user_id: Number(values?.user?.id),
    // user:values?.user_first_name,
    type: values?.leaveType?.leave_type_name,
    leave_id: values?.leaveType?.comp_leave_planner_id,
    earning_policy: values?.earningPolicy?.title,
    earning_start: moment(entitlementStartDate).format('YYYY-MM-DD') || moment(values?.startDate).format('YYYY-MM-DD'),
    valid_until: moment(entitlementEndDate).format('YYYY-MM-DD') || moment(values?.endDate).format('YYYY-MM-DD'),
    entitled: Number(values?.entitlement),
    carried_over: Number(values?.carriedOver),
    entitlement_id: Number(values?.entitlementId),
    year: String(values?.year),
    // yearSelect: values.yearSelect
  };

  if (bodyData?.entitlement_id === '') delete bodyData?.entitlement_id;

  const url = `${BASE_URL}/userEntitlement`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const updateCompanyEntitlement = async (values) => {
  const bodyData = {
    company_id: Number(getCompanyId()),
    user_id: Number(values?.user?.id),
    type: values?.leaveType?.leave_type_name,
    leave_id: values?.leaveType?.comp_leave_planner_id,
    earning_policy: values?.earningPolicy?.title,
    earning_start: moment(values?.startDate).format('YYYY-MM-DD'),
    valid_until: moment(values?.endDate).format('YYYY-MM-DD'),
    entitled: Number(values?.entitlement),
    carried_over: Number(values?.carriedOver),
    entitlement_id: Number(values?.entitlementId),
    year: String(values?.year),
  };

  if (bodyData?.entitlement_id === '') delete bodyData?.entitlement_id;

  const url = `${BASE_URL}/updateCompanyEntitlement`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const deleteEarningPolicy = async (id) => {
  const url = `${BASE_URL}/deleteEarningPolicy`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      earning_policy_id: id,
      company_id: Number(`${getCompanyId()}`),
    }),
  });
  const data = await response.json();
  return data;
};

export const getCompanyUsersWithEntitlementID = async (year, flagId) => {
  const url = `${BASE_URL}/getCompanyUsersWithEntitlementID/${getCompanyId()}/company/${year}?flag=${flagId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const getuserpayroll = async (page, rowsPerPage, order, orderBy, query) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const url = `${BASE_URL}/getUserPayroll?page=${page}&page_size=${rowsPerPage}&search=${query}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const getCurrency = async () => {
  const url = `${BASE_URL}/getCurrency`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const updatePayroll = async (values, effectiveDate, reviewDate) => {
  const bodyData = {
    basic_salary: Number(values?.basicSalary),
    company_id: Number(getCompanyId()),
    effective_date: moment(effectiveDate).format('YYYY-MM-DD') || moment(values?.startDate).format('YYYY-MM-DD'),
    next_review_date: moment(reviewDate).format('YYYY-MM-DD') || moment(values?.endDate).format('YYYY-MM-DD'),
    currency: values?.currency?.currency_code,
    user_id: Number(values?.user?.id),
    payroll_id: values?.payrollId,
    flag: 1,
  };

  if (bodyData?.payroll_id === '') delete bodyData?.payroll_id;

  const url = `${BASE_URL}/updatePayroll`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};
export const deletePayroll = async (id, payrollId) => {
  const url = `${BASE_URL}/deleteUserPayRoll`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: id,
      payroll_id: payrollId,
    }),
  });
  const data = await response.json();
  return data;
};
export const getCompanyUsersWithoutEntitlementID = async (year) => {
  const url = `${BASE_URL}/getCompanyUsersWithEntitlementID/${getCompanyId()}/company/${year}?flag=2`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const deleteUserLeave = async (info) => {
  const url = `${BASE_URL}/deleteUserLeave`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      employee_leave_id: info?.employee_leave_id,
      company_id: Number(`${getCompanyId()}`),
      user_id: info?.user_id,
    }),
  });
  const data = await response.json();
  return data;
};

export const updateWorkFlow = async (values) => {
  const bodyData = {
    approval_workflow_id: values?.approval_workflow_id,
    company_id: Number(getCompanyId()),
    user_id: Number(getUserId()),
    title: values?.code,
    description: values?.description,
    f_approver: values?.firstApprover?.role_auto_id,
    s_approver: values?.secondApprover?.role_auto_id,
    active_flag: values?.active ? 1 : 0,
    notify_additional_recipients_id: values?.tags?.map((x) => x?.user_username).join(','),
    // approver: { first: 1, second: 3, third: 5 }
  };

  if (bodyData?.approval_workflow_id === '') delete bodyData?.approval_workflow_id;

  const url = `${BASE_URL}/updateWorkFlow`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const getApprovalWorkFLow = async (searchValue, page, rowsPerPage, order, orderBy) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') {
    queryparams += `${orderBy}=${order}&`;
  }
  const url = `${BASE_URL}/getApprovalWorkFLow?page=${page}&page_size=${rowsPerPage}&search=${searchValue}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const deleteWorkFlow = async (info) => {
  const url = `${BASE_URL}/deleteWorkFlow`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      approval_workflow_id: info.approval_workflow_id,
      title: info.title,
    }),
  });
  const data = await response.json();
  return data;
};

export const GetCompanyWorkday = async (page, rowsPerPage, searchValue, order, orderBy) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') {
    queryparams += `${orderBy}=${order}&`;
  }
  let url = `${BASE_URL}/getCompanyWorkday?page=${page}&page_size=${rowsPerPage}&search=${searchValue}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getSignInStatus = async (page, rowsPerPage, order, orderBy, searchQuery, filterValues, filterStatus) => {
  const values = { ...filterValues };
  let queryparams = '';
  for (const [key, value] of Object.entries(values)) {
    queryparams += `${key}=${value}&`;
  }
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const url = `${BASE_URL}/getSignInStatus?all_company_id=${getCompanyId()}&page=${page}&page_size=${rowsPerPage}&search=${searchQuery}&${queryparams}&${filterStatus}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const signInStatus = async (info, user, status, isActive) => {
  if (status === 'weekly') {
    const bodyData = {
      user_id: user,
      company_id: getCompanyId(),
      monday: info[0].status ? info[0].status : 0,
      tuesday: info[1].status ? info[1].status : 0,
      wednesday: info[2].status ? info[2].status : 0,
      thursday: info[3].status ? info[3].status : 0,
      friday: info[4].status ? info[4].status : 0,
      saturday: info[5].status ? info[5].status : 0,
      sunday: info[6].status ? info[6].status : 0,
      remotely_tag_monthly: 0,
    };

    const url = `${BASE_URL}/signInStatus?weekly=1`;
    const response = await fetch(url, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    });
    const data = await response.json();
    return data;
  }
  const bodyData1 = {
    user_id: user,
    company_id: getCompanyId(),
    full_date: info,
    created_date: moment().format('DD-MM-YYYY'),
    office_tag: 1,
    office_tag_monthly: 0,
    active: 1,
  };
  const url = `${BASE_URL}/signInStatus?monthly=1`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData1),
  });
  const data = await response.json();
  return data;
};

export const updateUserSignInStatus = async (info, user, status) => {
  if (status === 'weekly') {
    const bodyData = {
      user_id: user,
      company_id: getCompanyId(),
      monday: info[0].status ? info[0].status : 0,
      tuesday: info[1].status ? info[1].status : 0,
      wednesday: info[2].status ? info[2].status : 0,
      thursday: info[3].status ? info[3].status : 0,
      friday: info[4].status ? info[4].status : 0,
      saturday: info[5].status ? info[5].status : 0,
      sunday: info[6].status ? info[6].status : 0,
      remotely_tag_monthly: 0,
    };
    const url = `${BASE_URL}/updateUserSignStatus?weekly=1`;
    const response = await fetch(url, {
      method: 'put',
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    });
    const data = await response.json();
    return data;
  }
  const bodyData1 = {
    user_id: user,
    company_id: getCompanyId(),
    full_date: info,
    created_date: moment().format('DD-MM-YYYY'),
    office_tag: 1,
    office_tag_monthly: 0,
    active: 1,
  };
  const url = `${BASE_URL}/updateUserSignStatus?monthly=1`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData1),
  });
  const data = await response.json();
  return data;
};

export const updateCompanyWorkday = async (info) => {
  const bodyData = {
    title: info.name,
    description: info.description,
    active: info.active ? 1 : 0,
    monday: info.monday ? 1 : 0,
    tuesday: info.tuesday ? 1 : 0,
    wednesday: info.wednesday ? 1 : 0,
    thursday: info.thursday ? 1 : 0,
    friday: info.friday ? 1 : 0,
    saturday: info.saturday ? 1 : 0,
    sunday: info.sunday ? 1 : 0,
    workday_id: info.id,
  };

  if (bodyData?.workday_id === 0 || !bodyData?.workday_id) delete bodyData?.workday_id;

  const url = `${BASE_URL}/updateCompanyWorkday`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const deleteWorkday = async (info) => {
  const url = `${BASE_URL}/deleteWorkday`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      workday_id: info.workday_id,
      title: info.title,
    }),
  });
  const data = await response.json();
  return data;
};
export const deleteHoliday = async (info) => {
  const url = `${BASE_URL}/deleteCompanyHoliday`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      holidays_id: info.holidays_id,
      company_id: info.company_id,
    }),
  });
  const data = await response.json();
  return data;
};

export const GetManagers = async () => {
  const url = `${BASE_URL}/getFunctionalProjectManagers`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getCompanyUsersForList = async (page, rows, query) => {
  let url = `${BASE_URL}/getCompanyUsers/${getCompanyId()}?sortByName=asc&`;
  if (query && query !== '') url += `search=${query}&`;

  if (page !== null) url += `page=${page}&`;

  if (rows !== null) url += `page_size=${rows}`;

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const GetAllComingHolidays = async (days) => {
  const url = `${BASE_URL}/getAllCompanyComingHolidays?days=${days}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const GetCompanyComingUserBirthDay = async (days) => {
  const url = `${BASE_URL}/getCompanyComingUserBirthDay?days=${days}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const GetUserLastLogin = async () => {
  const url = `${BASE_URL}/getUserLastLogin`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const GetUpcomingSalaryReviews = async () => {
  const url = `${BASE_URL}/getComingCompanySalaryReview`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  // debugger;
  return data;
};
export const GetCompanyComingLeavesApproval = async () => {
  const url = `${BASE_URL}/getComingCompanyLeaveApprove`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const GetCompanyComingUserAnniversary = async () => {
  const url = `${BASE_URL}/getUpComingUserAnniversary?sort=asc`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const GetCompanyComingEndProbation = async () => {
  const url = `${BASE_URL}/getUpComingEndProbation`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const deleteEntitlement = async (info) => {
  const url = `${BASE_URL}/deleteCompanyEntitlement/${info.entitlement_id}`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const postLeaveApproveByFirstApprover = async (info, no) => {
  const bodyDate = {
    employee_leave_id: info.employee_leave_id,
    leave_flag: no,
  };

  const url = `${BASE_URL}/leaveApproveByFirstApprover`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

export const postLeaveApproveBySecondApprover = async (info, no) => {
  const bodyDate = {
    employee_leave_id: info.employee_leave_id,
    leave_flag: no,
  };

  const url = `${BASE_URL}/leaveApproveBySecondApprover`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};

// export const postLeaveApproveByHR = async (info, no, comment) => {
//   const bodyDate = {
//     employee_leave_id: info.employee_leave_id,
//     leave_flag: no,
//     message: comment,
//   };

//   const url = `${BASE_URL}/leaveApproveByHr/2023`;
//   const response = await fetch(url, {
//     method: 'put',
//     headers: {
//       Authorization: `Bearer ${jwtToken()}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(bodyDate),
//   });
//   const data = await response.json();
//   return data;
// };

export const postLeaveApproveByHR = async (info, no, comment, year) => {
  const bodyDate = {
    employee_leave_id: info.employee_leave_id,
    leave_flag: no,
    message: comment,
  };

  const currentYear = new Date().getFullYear();
  const url = `${BASE_URL}/leaveApproveByHr/${year}`;

  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });

  const data = await response.json();
  return data;
};

export const approveSuggestedAttendance = async (info, approver, flag) => {
  const bodyDate =
    approver === 'hr'
      ? {
          attendance_auto_id: info.attendance_auto_id,
          approved_by_hr: flag,
        }
      : approver === 'fm'
      ? {
          attendance_auto_id: info.attendance_auto_id,
          approved_by_functional_manager: flag,
        }
      : {
          attendance_auto_id: info.attendance_auto_id,
          approved_by_recruitment_manager: flag,
        };

  const url = `${BASE_URL}/approveSuggestedAttendance`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};
export const GetAllRoles = async (search, page, rowsPerPage, order, orderBy) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
  const url = `${BASE_URL}/adminGetUserRoles?page=${page}&page_size=${rowsPerPage}&search=${search}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const adminUserEnableDisable = async (usedId, no) => {
  const bodyDate = {
    user_is_active: no,
    id: usedId,
  };

  const url = `${BASE_URL}/adminUserEnableDisable`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyDate),
  });
  const data = await response.json();
  return data;
};
export const getCompaniesAdmin = async (page, page_size, search, order, orderBy) => {
  // const jwtToken = localStorage.getItem('jwtToken');
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
  const response = await fetch(
    `${BASE_URL}/admingetallcompanies?page=${page}&page_size=${page_size}&search=${search}&${queryparams}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    console.error(response.status);
  }
  return data;
};
export const adminDeleteUser = async (userId) => {
  const url = `${BASE_URL}/adminDeleteUser/${userId}`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const adminDeleteCompany = async (id = '') => {
  const url = `${BASE_URL}/adminDeleteCompany/${id}`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const adminCompanyAction = async (id = '', flag = -1) => {
  const bodyData = {
    company_flag: flag,
  };
  const url = `${BASE_URL}/adminCompanyAction/${id}`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};
export const adminGetEmailTemplate = async () => {
  const url = `${BASE_URL}/adminGetEmailTemplate`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const GetAllEmailSettings = async () => {
  const url = `${BASE_URL}/adminGetEmailSetting`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
export const adminUpdateEmailTemplate = async (bodyData = []) => {
  const url = `${BASE_URL}/adminUpdateEmailTemplate`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};
export const enableCompany = async (params_token) => {
  const urlToken = params_token;
  const response = await fetch(`${BASE_URL}/enableCompany`, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${urlToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (response.status >= 400) {
    // throw new Error(data.errors);
    console.error(response.status);
  }
  return data;
};

export const addCompanyDocuments = async (dataToSend) => {
  const url = `${BASE_URL}/companyDocuments`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  });
  const data = await response.json();
  return data;
};
export const updateCompanyDocuments = async (dataToSend, companyDocumentAutoId) => {
  const url = `${BASE_URL}/updateCompanyDocument?company_document_auto_id=${companyDocumentAutoId}`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  });
  const data = await response.json();
  return data;
};

export const getCompanyDocument = async (filterValues, page, rowsPerPage, order, orderBy, searchQuery, companyId) => {
  const values = { ...filterValues };
  let queryparams = '';
  for (const [key, value] of Object.entries(values)) {
    queryparams += `${key}=${value}&`;
  }
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  if (page && page !== '') queryparams += `page=${page}&`;
  if (rowsPerPage && rowsPerPage !== '') queryparams += `page_size=${rowsPerPage}&`;

  const url = `${BASE_URL}/getCompanyDocuments/?${queryparams}getCompanyDocument=${companyId}&search=${searchQuery}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getAdminCompanyDocumentUser = async (filterValues, page, rowsPerPage, order, orderBy, search) => {
  const values = { ...filterValues };
  let queryparams = '';
  for (const [key, value] of Object.entries(values)) {
    queryparams += `${key}=${value}&`;
  }
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const url = `${BASE_URL}/getAdminCompanyDocumentUser/?page=${page}&page_size=${rowsPerPage}&${queryparams}search=${search}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const updateAdminUserDocumentRecord = async (dataToSend, userCompanyDocumentId) => {
  const companyId = Number(getCompanyId());
  const url = `${BASE_URL}/updateAdminUserDocumentRecord?user_company_document_id=${userCompanyDocumentId}&company_id=${companyId}`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  });
  const data = await response.json();
  return data;
};

export const getAllCompanies = async (id) => {
  const url = `${BASE_URL}/getChildCompany?ParentCompanyId=${id}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const deleteCompanyDocument = async (id, companyDocumentAutoId) => {
  const url = `${BASE_URL}/deleteCompanyDocument?company_document_auto_id=${companyDocumentAutoId}`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      company_id: id,
    }),
  });
  const data = await response.json();
  return data;
};

export const postProjectPriorityChange = async (id, priority, task) => {
  const queryParams = new URLSearchParams({ priority_id: priority.priority_auto_id }).toString();
  const url = `${BASE_URL}/updateStatus?${queryParams}`;
  const bodyData = {
    priority_id: priority.priority_auto_id,
    proj_auto_id: task.proj_auto_id,
  };
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const postProjectStatusChange = async (id, status, task) => {
  const queryParams = new URLSearchParams({ status_id: status.project_status_auto_id }).toString();
  const url = `${BASE_URL}/updateStatus?${queryParams}`;
  const bodyData = {
    status_id: status.project_status_auto_id,
    proj_auto_id: task.proj_auto_id,
  };
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  const data = await response.json();
  return data;
};

export const addCompanyAsset = async (dataToSend) => {
  const url = `${BASE_URL}/itemrecord`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  });
  const data = await response.json();
  return data;
};

export const getCompanyAsset = async (page, rowsPerPage, order, orderBy, searchQuery, companyId) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;
  if (page && page !== '') queryparams += `page=${page}&`;
  if (rowsPerPage && rowsPerPage !== '') queryparams += `page_size=${rowsPerPage}&`;

  const url = `${BASE_URL}/getItemRecord/?${queryparams}company_id=${companyId}&search=${searchQuery}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getAssetsActivity = async (assetsID) => {
  const url = `${BASE_URL}/asset-activity/${assetsID}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const updateCompanyAssets = async (dataToSend, companyAssetAutoId) => {
  const url = `${BASE_URL}/updateitemrecord?item_auto_id=${companyAssetAutoId}`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  });
  const data = await response.json();
  return data;
};

export const addAssetRecord = async (dataToSend) => {
  const url = `${BASE_URL}/assetrecord`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  });
  const data = await response.json();
  return data;
};

export const getCompanyAssetRecord = async (
  filterValues,
  page,
  rowsPerPage,
  order,
  orderBy,
  searchQuery,
  companyId,
  csv = '',
  query_params
) => {
  const values = { ...filterValues };
  let queryparams = '';
  for (const [key, value] of Object.entries(values)) {
    queryparams += `${key}=${value}&`;
  }
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  if (page && page !== '') queryparams += `page=${page}&`;
  if (rowsPerPage && rowsPerPage !== '') queryparams += `page_size=${rowsPerPage}&`;

  const selected_query_params = query_params || '';

  let url = `${BASE_URL}/getassetrecord/?company_id=${companyId}&search=${searchQuery}&${queryparams}`;

  if (csv !== '') {
    url += `&${csv}`;
  }

  if (selected_query_params !== '') {
    url += `&columns=${selected_query_params}`;
  }

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const updateAllAssetListRecord = async (dataToSend, companyAssetAutoId) => {
  const url = `${BASE_URL}/updateassetrecord?asset_auto_id=${companyAssetAutoId}`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  });
  const data = await response.json();
  return data;
};

export const getUserAssetsRecord = async (
  filterValues,
  page,
  rowsPerPage,
  order,
  orderBy,
  searchQuery,
  companyId,
  assignTo
) => {
  const values = { ...filterValues };
  let queryparams = '';
  for (const [key, value] of Object.entries(values)) {
    queryparams += `${key}=${value}&`;
  }
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  if (page && page !== '') queryparams += `page=${page}&`;
  if (rowsPerPage && rowsPerPage !== '') queryparams += `page_size=${rowsPerPage}&`;

  const url = `${BASE_URL}/getassetrecord/?assign_to=${assignTo}&company_id=${companyId}&search=${searchQuery}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const deleteUserAsset = async (id, userId, companyAssetAutoId) => {
  const url = `${BASE_URL}/deleteUserAsset?company_id=${id}&assign_to=${userId}&asset_auto_id=${companyAssetAutoId}`;
  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      company_id: id,
    }),
  });
  const data = await response.json();
  return data;
};

export const getSuggestedAttendance = async (
  page,
  rowsPerPage,
  selectedUsers,
  dateRange,
  searchQuery,
  companyId,
  filterStatus,
  csv = '',
  query_params,
  userTimeZone
) => {
  const userZoneTime = userTimeZone || '';
  const selected_query_params = query_params || '';
  let url = `${BASE_URL}/getSuggestedAttendance/CompId/${companyId}?user_time_zone=${userZoneTime}&page=${page}&page_size=${rowsPerPage}&userId=${selectedUsers}&startDate=${
    dateRange[0] ? toISOLocal(dateRange[0]) : ''
  }&endDate=${dateRange[1] ? toISOLocal(dateRange[1]) : ''}&search=${searchQuery}`; // Removed filterStatus from the base URL

  // Conditionally add filterStatus to the URL if it's not null
  if (filterStatus !== null) {
    url += `&${filterStatus}`;
  }

  if (csv !== '') {
    url += `&${csv}`;
  }

  if (selected_query_params !== '') {
    url += `&columns=${selected_query_params}`;
  }

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getCompanyUsersSuperAdmin = async (page, pageSize, search) => {
  const url = `${BASE_URL}/getAllCompanyUser?page=${page}&page_size=${pageSize}&search=${search}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const addSuperAdmin = async (dataToSend) => {
  const url = `${BASE_URL}/addSuperAdmin?add=1`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  });
  const data = await response.json();
  return data;
};

export const getAllSuperAdmin = async (page, pageSize, search, order, orderBy) => {
  let queryparams = '';
  if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&`;
  const url = `${BASE_URL}/getAllSuperAdmin?page=${page}&page_size=${pageSize}&search=${search}&${queryparams}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const deleteSuperAdmin = async (userId, userCompanyId, superAdminExist, superAdminActive) => {
  const dataToSend = {
    user_id: userId,
    company_id: userCompanyId,
    super_admin: 0,
    super_admin_active: 0,
  };
  const url = `${BASE_URL}/addSuperAdmin?delete=1`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  });
  const data = await response.json();
  return data;
};

export const enableSuperAdmin = async (id, flag, userCompanyId, superAdminExist) => {
  const dataToSend = {
    user_id: id,
    super_admin_active: flag,
    super_admin: superAdminExist,
    company_id: userCompanyId,
  };
  const url = `${BASE_URL}/addSuperAdmin?disable=1`;
  const response = await fetch(url, {
    method: 'put',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
  });
  const data = await response.json();
  return data;
};

export const getCheckSuperAdmin = async () => {
  const url = `${BASE_URL}/getCheckSuperAdmin`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getReverseUser = async (superAdminToUser) => {
  const url = `${BASE_URL}/reverseUser?user_id=${superAdminToUser}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getUserBreaks = async (
  size,
  page,
  order,
  orderBy,
  userid,
  search,
  dateRange,
  filterStatus,
  setbreakDuration,
  userTimeZone,
  csv = '',
  query_params
) => {
  const today = new Date();
  const last30Days = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const startDateRange = dateRange[0] ? new Date(dateRange[0]) : last30Days;
  const endDateRange = dateRange[1] ? new Date(dateRange[1]) : today;

  const selected_query_params = query_params || '';

  // let queryparams = '';
  // if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;

  let queryparams = '';
  if (orderBy && orderBy !== '') {
    queryparams += `${orderBy}=${order}`;
  } else if (csv !== '' || selected_query_params !== '') {
    queryparams += 'sortByDate=desc';
  }

  let url = `${BASE_URL}/getUserBreaks?user_time_zone=${userTimeZone}&company_id=${getCompanyId()}&assign_to=${userid}&page_size=${size}&page=${page}&${queryparams}&search=${search}&start_date=${formatDate(
    startDateRange
  )}&end_date=${formatDate(endDateRange)}`;

  if (csv !== '') {
    url += `&${csv}`;
  }

  if (selected_query_params !== '') {
    url += `&columns=${selected_query_params}`;
  }

  if (filterStatus !== null) {
    url += `&${filterStatus}`;
  }

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};

export const getUserBreaksReport = async (
  size,
  page,
  order,
  orderBy,
  userid,
  search,
  dateRange,
  filterStatus,
  setbreakDuration,
  userTimeZone,
  csv = '',
  query_params
) => {
  const breakStatus = setbreakDuration || '';
  const today = new Date();
  const last30Days = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const startDateRange = dateRange[0] ? new Date(dateRange[0]) : last30Days;
  const endDateRange = dateRange[1] ? new Date(dateRange[1]) : today;

  const selected_query_params = query_params || '';

  // let queryparams = '';
  // if (orderBy && orderBy !== '') queryparams += `${orderBy}=${order}&${queryparams}`;

  let queryparams = '';
  if (orderBy && orderBy !== '') {
    queryparams += `${orderBy}=${order}`;
  } else if (csv !== '' || selected_query_params !== '') {
    queryparams += 'sortByDate=desc';
  }

  let url = `${BASE_URL}/getUserBreaksReport?user_time_zone=${userTimeZone}&company_id=${getCompanyId()}&assign_to=${userid}&break_duration=${breakStatus}&page_size=${size}&page=${page}&${queryparams}&search=${search}&start_date=${formatDate(
    startDateRange
  )}&end_date=${formatDate(endDateRange)}`;

  if (csv !== '') {
    url += `&${csv}`;
  }

  if (selected_query_params !== '') {
    url += `&columns=${selected_query_params}`;
  }

  if (filterStatus !== null) {
    url += `&${filterStatus}`;
  }

  const response = await fetch(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${jwtToken()}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data;
};
