import { useSnackbar } from 'notistack5';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PATH_DASHBOARD } from '../routes/paths';
// config
import { companyConfig } from '../utils/snackbarConfig';
import { getCompanyId } from '../utilities/utilities';

// ----------------------------------------------------------------------

CompanyGuard.propTypes = {
  children: PropTypes.node
};

export default function CompanyGuard({ children }) {
  const { enqueueSnackbar } = useSnackbar();

  if (!getCompanyId()) {
    enqueueSnackbar(companyConfig.not_exist, { variant: 'error' });
    return <Navigate to={PATH_DASHBOARD.company.edit} />;
  }

  return <>{children}</>;
}
