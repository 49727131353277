// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ADMIN = '/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, ''),
  loginUnprotected: path(ROOTS_AUTH, 'login-unprotected'),
  register: path(ROOTS_AUTH, 'register'),
  registerUnprotected: path(ROOTS_AUTH, 'register-unprotected'),
  resetPassword: path(ROOTS_AUTH, 'forget-password'),
  verify: path(ROOTS_AUTH, 'verify'),
};

export const PATH_PAGE = {
  emailSent: '/email-sent',

  page404: '/404',
  page500: '/500',
  components: '/components',
};
export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  superAdmin: {
    admin: path(ROOTS_ADMIN, '/charts'),
  },
  superAdminsList: {
    list: path(ROOTS_ADMIN, '/superadminlist'),
  },
  emailConfigurations: {
    email: path(ROOTS_ADMIN, '/email-configuration'),
  },
  emailTemplates: {
    templates: path(ROOTS_ADMIN, '/email-templates'),
  },
  roleManagement: {
    roles: path(ROOTS_ADMIN, '/role-management'),
  },
  permissionManagement: {
    permissions: path(ROOTS_ADMIN, '/permission-management'),
  },
  userManagement: {
    users: path(ROOTS_ADMIN, '/user-management'),
  },
  companyManagement: {
    companies: path(ROOTS_ADMIN, '/company-management'),
  },
  ownerManagement: {
    owners: path(ROOTS_ADMIN, '/owner-management'),
  },
  generalSettingsManagement: {
    general: path(ROOTS_ADMIN, '/general-settings'),
  },
};
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },

  calendar: path(ROOTS_DASHBOARD, '/calendar'),

  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    view: path(ROOTS_DASHBOARD, '/user/view'),
    edit: path(ROOTS_DASHBOARD, '/user/edit'),

    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
  },
  account: {
    root: path(ROOTS_DASHBOARD, '/account'),
    change_password: path(ROOTS_DASHBOARD, '/account/change-password'),
  },

  projects: {
    root: path(ROOTS_DASHBOARD, '/projects/cards'),
    list: path(ROOTS_DASHBOARD, '/projects/list'),
    new: path(ROOTS_DASHBOARD, '/projects/new'),
    view: path(ROOTS_DASHBOARD, '/projects/view'),
    edit: path(ROOTS_DASHBOARD, '/projects/edit'),
  },
  tasks: {
    // root: path(ROOTS_DASHBOARD, '/tasks/all'),
    root: path(ROOTS_DASHBOARD, '/tasks/list'),
    mytasklist: path(ROOTS_DASHBOARD, '/tasks/mytasklist'),
    new: path(ROOTS_DASHBOARD, '/tasks/new'),
    view: path(ROOTS_DASHBOARD, '/tasks/view'),
    edit: path(ROOTS_DASHBOARD, '/tasks/edit'),
  },
  kanban: {
    board: path(ROOTS_DASHBOARD, '/kanban/board'),
    mytaskskanban: path(ROOTS_DASHBOARD, '/kanban/mytaskskanban'),
    new: path(ROOTS_DASHBOARD, '/kanban/new'),
    view: path(ROOTS_DASHBOARD, '/kanban/view'),
    edit: path(ROOTS_DASHBOARD, '/kanban/edit'),
  },
  timesheet: {
    root: path(ROOTS_DASHBOARD, '/timesheet'),
    weekly: path(ROOTS_DASHBOARD, '/timesheet/weekly'),
    vieweekly: path(ROOTS_DASHBOARD, '/timesheet/view-weekly'),
    list: path(ROOTS_DASHBOARD, '/timesheet/list'),
    missingList: path(ROOTS_DASHBOARD, '/timesheet/missing-timesheet'),
    missingListUser: path(ROOTS_DASHBOARD, '/timesheet/missing-timesheet-user'),
    calendar: path(ROOTS_DASHBOARD, '/timesheet/calendar'),
  },
  team: {
    root: path(ROOTS_DASHBOARD, '/team/all'),
    userDocuments: path(ROOTS_DASHBOARD, '/team/documents'),
    viewuserdocument: path(ROOTS_DASHBOARD, '/team/documents/view-user-document'),
    new: path(ROOTS_DASHBOARD, '/team/all/new'),
    edit: path(ROOTS_DASHBOARD, '/team/all/edit'),
    view: path(ROOTS_DASHBOARD, '/team/all/view'),
  },
  company: {
    root: path(ROOTS_DASHBOARD, '/company/view'),
    documents: path(ROOTS_DASHBOARD, '/company/documents'),
    new: path(ROOTS_DASHBOARD, '/company/view/new'),
    // documentView: path(ROOTS_DASHBOARD, '/company/documentview'),
    // documentEdit: path(ROOTS_DASHBOARD, '/company/documentedit'),
    viewDocument: path(ROOTS_DASHBOARD, '/company/documents/view-document'),
    addDocument: path(ROOTS_DASHBOARD, '/company/documents/add-document'),
    edit: path(ROOTS_DASHBOARD, '/company/view/edit'),
    add: path(ROOTS_DASHBOARD, '/company/view/subcompany'),
  },
  attendance: {
    root: path(ROOTS_DASHBOARD, '/attendance/view'),
    // dashboard: path(ROOTS_DASHBOARD, '/attendance/dashboard'),
    timing: path(ROOTS_DASHBOARD, '/attendance/timing'),
    log: path(ROOTS_DASHBOARD, '/attendance/log'),
    userWorkdays: path(ROOTS_DASHBOARD, '/attendance/officedays'),
    viewUserWorkDays: path(ROOTS_DASHBOARD, '/attendance/officedays/view-officedays'),
    addUserWorkDays: path(ROOTS_DASHBOARD, '/attendance/officedays/add-officedays'),
    lateSignInApproval: path(ROOTS_DASHBOARD, '/attendance/latesignin'),
    breakView: path(ROOTS_DASHBOARD, '/attendance/breakview'),
    breakReport: path(ROOTS_DASHBOARD, '/attendance/breakreport'),
  },
  leaves: {
    root: path(ROOTS_DASHBOARD, '/leaves/all'),
    user: path(ROOTS_DASHBOARD, '/leaves/my-leaves'),
    viewLeaves: path(ROOTS_DASHBOARD, '/leaves/all/view-leave'),
    addLeaves: path(ROOTS_DASHBOARD, '/leaves/all/add-leave'),
    viewMyLeaves: path(ROOTS_DASHBOARD, '/leaves/my-leaves/view-leave'),
    addMyLeaves: path(ROOTS_DASHBOARD, '/leaves/my-leaves/add-leave'),
    pendingApproval: path(ROOTS_DASHBOARD, '/leaves/pending-approvals'),
    viewpendingApproval: path(ROOTS_DASHBOARD, '/leaves/pending-approvals/view-pending-approvals'),
    management: path(ROOTS_DASHBOARD, '/leaves/management'),
    // records: path(ROOTS_DASHBOARD, '/leaves/records'),
    holidays: path(ROOTS_DASHBOARD, '/leaves/holidays'),
    viewholidays: path(ROOTS_DASHBOARD, '/leaves/holidays/view-holidays'),
    addholidays: path(ROOTS_DASHBOARD, '/leaves/holidays/add-holidays'),
    schedule: path(ROOTS_DASHBOARD, '/leaves/schedule'),
    review: path(ROOTS_DASHBOARD, '/leaves/review'),
    transactions: path(ROOTS_DASHBOARD, '/leaves/transactions'),
    entitlement: path(ROOTS_DASHBOARD, '/leaves/entitlement'),
    viewentitlement: path(ROOTS_DASHBOARD, '/leaves/management/view-entitlement'),
    addentitlement: path(ROOTS_DASHBOARD, '/leaves/management/add-entitlement'),
    types: path(ROOTS_DASHBOARD, '/leaves/types'),
    viewleavetypes: path(ROOTS_DASHBOARD, '/leaves/types/leavetypes'),
    addleavetypes: path(ROOTS_DASHBOARD, '/leaves/types/add-leavetypes'),
    workDays: path(ROOTS_DASHBOARD, '/leaves/workdays'),
    addworkDay: path(ROOTS_DASHBOARD, '/leaves/workdays/add-workday'),
    viewworkDay: path(ROOTS_DASHBOARD, '/leaves/workdays/view-workday'),
    earningPolicy: path(ROOTS_DASHBOARD, '/leaves/earning-policy'),
    viewearningPolicy: path(ROOTS_DASHBOARD, '/leaves/earning-policy/view-earning-policy'),
    addearningPolicy: path(ROOTS_DASHBOARD, '/leaves/earning-policy/add-earning-policy'),
    approvalWorkflow: path(ROOTS_DASHBOARD, '/leaves/approval-workflow'),
    viewApprovalWorkflow: path(ROOTS_DASHBOARD, '/leaves/approval-workflow/view-approval-workflow'),
    addApprovalWorkflow: path(ROOTS_DASHBOARD, '/leaves/approval-workflow/add-approval-workflow'),
  },
  payroll: {
    // root: path(ROOTS_DASHBOARD, '/payroll/all'),
    salaryAdjustment: path(ROOTS_DASHBOARD, '/payroll/salary-adjustment'),
    new: path(ROOTS_DASHBOARD, '/payroll/salary-adjustment/new-salary-adjustment'),
    edit: path(ROOTS_DASHBOARD, '/payroll/edit'),
    view: path(ROOTS_DASHBOARD, '/payroll/salary-adjustment/view-salary-adjustment'),
  },

  assets: {
    root: path(ROOTS_DASHBOARD, '/assets/assettypes'),
    ViewAssetType: path(ROOTS_DASHBOARD, '/assets/assettypes/view-asset-type'),
    AddAssetType: path(ROOTS_DASHBOARD, '/assets/assettypes/add-asset-type'),
    allAssetsList: path(ROOTS_DASHBOARD, '/assets/allassetslist'),
    myAssetsList: path(ROOTS_DASHBOARD, '/assets/myassetslist'),
    ViewAsset: path(ROOTS_DASHBOARD, '/assets/allassetslist/view-asset'),
    MyViewAsset: path(ROOTS_DASHBOARD, '/assets/myassetslist/view-my-asset'),
    AddAsset: path(ROOTS_DASHBOARD, '/assets/allassetslist/add-asset'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
