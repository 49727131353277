import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// icons
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, Button, Drawer, Select, InputLabel, MenuItem } from '@material-ui/core';
import MuiFormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import CollapseButton from './CollapseButton';
//
import sidebarConfig from './SidebarConfig';
import { getLeaveForApprovalsCheck, getProjectbyUser } from '../../server/server';
import {
  getGlobalProjectId,
  setGlobalProjectId,
  getRoleId,
  getUserId,
  setProjectExist,
} from '../../utilities/utilities';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 220;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

const FormControl = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      fontSize: 14,
    },
    '& .MuiInputLabel-root': {
      fontSize: 14,
      lineHeight: 1,
      marginTop: -3,
    },
    '& .MuiInputBase-input': {
      paddingTop: 9,
      paddingBottom: 10,
    },
  },
})(MuiFormControl);

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  pendingApproval: PropTypes.number,
  setPendingApproval: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, pendingApproval, setPendingApproval }) {
  const { pathname } = useLocation();
  const [data, setData] = useState(sidebarConfig());
  const [project, setProject] = useState([]);
  const [showApproval, setShowApproval] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedValue, setSelectedValue] = useState(Number(getGlobalProjectId()));

  const changeSelect = (e) => {
    setSelectedValue(e.target.value);
    setGlobalProjectId(e.target.value);
    window.location.reload();
  };

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    getLeaveForApprovalsCheck().then((item) => {
      const dt = item.leaevAllData.filter(
        (x) =>
          ((x.leave_flag === 0 || x.leave_flag === 1) && x?.manager[0]?.recruitment_manager === Number(getUserId())) ||
          x?.manager[0]?.functional_manager === Number(getUserId())
      );
      if (typeof setPendingApproval === 'function') {
        if (dt?.length) {
          setPendingApproval(dt?.length);
        } else {
          setPendingApproval(0);
        }
      }
    });
    setSelectedValue(getGlobalProjectId());
    let dash;
    getProjectbyUser('', page, 500, '', '', '', (dash = 1)).then((res) => {
      if (!res?.Message && res?.projectTaskdata?.length > 0) {
        const array = res.projectTaskdata
          .map((taskData) => {
            if (taskData.Projects && taskData.Projects.length) {
              return { ...taskData.Projects[0] };
            }
            return null;
          })
          .filter(Boolean);

        setProject(array);

        setProjectExist(1);
      } else {
        setProject([]);
        setProjectExist(0);
      }
    });
    // }
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    setData(sidebarConfig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderButton = () => {
    const newProjectCard = pathname.includes('/projects/cards');
    const newProjectList = pathname.includes('/projects/list');
    const newTaskList = pathname.includes('/tasks/list');
    const newMyTaskList = pathname.includes('/tasks/mytasklist');
    const newTask = pathname.includes('/tasks');
    const newTaskBoard = pathname.includes('/kanban/board');
    const newMyKanbanTask = pathname.includes('/kanban/mytaskskanban');
    const newKanbanTask = pathname.includes('/kanban');
    const newProject = pathname.includes('/projects');
    const addTime = pathname.includes('/log/view');
    const newMember = pathname.includes('/team/all') && [1, 3, 8].includes(Number(getRoleId()));

    // const editProfile = pathname.includes('/user/profile');
    const path = () => {
      if (newProjectCard || newProjectList) return PATH_DASHBOARD.projects.new;
      if (newTaskList || newMyTaskList) return PATH_DASHBOARD.tasks.new;
      if (newTaskBoard || newMyKanbanTask) return PATH_DASHBOARD.kanban.new;
      if (addTime) return PATH_DASHBOARD.timesheet.add;
      if (newMember) return PATH_DASHBOARD.team.new;
      // if (editProfile) return PATH_DASHBOARD.user.edit;
      return '';
    };
    if (
      newProjectCard ||
      newProjectList ||
      newTaskList ||
      newMyTaskList ||
      newTaskBoard ||
      newMyKanbanTask ||
      addTime ||
      newMember
    ) {
      return (
        <Box mt={2}>
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            component={RouterLink}
            to={path()}
            startIcon={<Icon icon={plusFill} />}
          >
            {(() => {
              if (newProject) return 'New project';
              if (newTask || newKanbanTask) return 'New task';
              if (addTime) return 'Add time';
              if (newMember) return 'New member';
              return '';
            })()}
          </Button>
        </Box>
      );
    }
    return '';
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        sx={{
          px: 2.5,
          pt: 2,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center',
          }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <Logo />
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
          </MHidden>
        </Stack>

        {isCollapse ? (
          <></>
        ) : (
          <>
            {(() => {
              if (project[0] !== undefined && project.length !== 0)
                return (
                  <>
                    {pathname.includes('/') ? (
                      <Box mt={2.75}>
                        <FormControl fullWidth>
                          <InputLabel id="project-select-label">Project</InputLabel>
                          <Select
                            labelId="project-select-label"
                            id="project-select"
                            label="Project"
                            placeholder="Choose project"
                            onChange={changeSelect}
                            MenuProps={{ sx: { zIndex: 2001 } }}
                            value={selectedValue}
                            defaultValue={selectedValue}
                          >
                            {project?.sort(compare)?.map((arr) => (
                              <MenuItem key={arr} value={arr.proj_auto_id}>
                                {arr.proj_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    ) : null}
                  </>
                );
              return null;
            })()}
            {renderButton()}
          </>
        )}
      </Stack>

      <NavSection
        isCollapse={isCollapse}
        showApproval={showApproval}
        navConfig={data}
        isShow={!isCollapse}
        pendingApproval={pendingApproval}
        setPendingApproval={setPendingApproval}
      />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        zIndex: 2000,
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88),
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

function compare(a, b) {
  if (a.proj_name < b.proj_name) {
    return -1;
  }
  if (a.proj_name > b.proj_name) {
    return 1;
  }
  return 0;
}
