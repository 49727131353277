import CryptoJS from 'crypto-js';
import moment from 'moment';

export const getUserEmail = () => {
  if (localStorage.getItem('sd_key_1')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_1'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserEmail = (email = '') => {
  localStorage.setItem(
    'sd_key_1',
    CryptoJS.AES.encrypt(JSON.stringify(email), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getRoleId = () => {
  if (localStorage.getItem('sd_key_2')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_2'), process.env.REACT_APP_ENCRYPTION_KEY);
    return Number(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
  }
  return null;
};

export const setRoleId = (id = null) => {
  localStorage.setItem(
    'sd_key_2',
    CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getUserId = () => {
  if (localStorage.getItem('sd_key_3')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_3'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};
export const getCompanyName = () => {
  if (localStorage.getItem('sd_key_4')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_4'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserId = (id = null) => {
  localStorage.setItem(
    'sd_key_3',
    CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};
export const setCompanyName = (companyName = '') => {
  localStorage.setItem(
    'sd_key_4',
    CryptoJS.AES.encrypt(JSON.stringify(companyName), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getIsOwner = () => {
  if (localStorage.getItem('sd_key_5')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_5'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setIsOwner = (owner = 0) => {
  localStorage.setItem(
    'sd_key_5',
    CryptoJS.AES.encrypt(JSON.stringify(owner), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getUserState = () => {
  if (localStorage.getItem('sd_key_6')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_6'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserState = (state = null) => {
  localStorage.setItem(
    'sd_key_6',
    CryptoJS.AES.encrypt(JSON.stringify(state), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};
export const getUserName = () => {
  if (localStorage.getItem('sd_key_7')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_7'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserName = (sd_key_7 = '') => {
  localStorage.setItem(
    'sd_key_7',
    CryptoJS.AES.encrypt(JSON.stringify(sd_key_7), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};
export const getUserSession = () => {
  if (localStorage.getItem('sd_key_8')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_8'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserSession = (sd_key_8 = null) => {
  localStorage.setItem(
    'sd_key_8',
    CryptoJS.AES.encrypt(JSON.stringify(sd_key_8), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};
export const getGlobalProjectId = () => {
  if (localStorage.getItem('sd_key_9')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_9'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setGlobalProjectId = (id = null) => {
  localStorage.setItem(
    'sd_key_9',
    CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getUser = () => {
  if (localStorage.getItem('sd_key_10')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_10'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUser = (user = null) => {
  localStorage.setItem(
    'sd_key_10',
    CryptoJS.AES.encrypt(JSON.stringify(user), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getCompanyId = () => {
  if (localStorage.getItem('sd_key_11')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_11'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setCompanyId = (id = null) => {
  localStorage.setItem(
    'sd_key_11',
    CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getUserCountry = () => {
  if (localStorage.getItem('sd_key_12')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_12'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserCountry = (country = null) => {
  localStorage.setItem(
    'sd_key_12',
    CryptoJS.AES.encrypt(JSON.stringify(country), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getUserCity = () => {
  if (localStorage.getItem('sd_key_13')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_13'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserCity = (city = null) => {
  localStorage.setItem(
    'sd_key_13',
    CryptoJS.AES.encrypt(JSON.stringify(city), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getCountryCodeIso = () => {
  if (localStorage.getItem('sd_key_14')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_14'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setCountryCodeIso = (id = null) => {
  localStorage.setItem(
    'sd_key_14',
    CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getProjectExist = () => {
  if (localStorage.getItem('sd_key_15')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_15'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setProjectExist = (id = null) => {
  localStorage.setItem(
    'sd_key_15',
    CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};
export const getAccessToken = () => {
  if (localStorage.getItem('sd_key_16')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_16'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setAccessToken = (token = null) => {
  localStorage.setItem(
    'sd_key_16',
    CryptoJS.AES.encrypt(JSON.stringify(token), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};
export const getJwtToken = () => {
  if (localStorage.getItem('sd_key_17')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_16'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setJwtToken = (token = null) => {
  localStorage.setItem(
    'sd_key_17',
    CryptoJS.AES.encrypt(JSON.stringify(token), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getAccessTokenForSuperAdmin = () => {
  if (localStorage.getItem('sd_key_16')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_16'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setAccessTokenForSuperAdmin = (token = null) => {
  localStorage.setItem(
    'sd_key_16',
    CryptoJS.AES.encrypt(JSON.stringify(token), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getJwtTokenForSuperAdmin = () => {
  if (localStorage.getItem('sd_key_17')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_16'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setJwtTokenForSuperAdmin = (token = null) => {
  localStorage.setItem(
    'sd_key_17',
    CryptoJS.AES.encrypt(JSON.stringify(token), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const getUserIdSuperAdmin = () => {
  if (localStorage.getItem('sd_key_20')) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('sd_key_20'), process.env.REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return null;
};

export const setUserIdSuperAdmin = (id = null) => {
  localStorage.setItem(
    'sd_key_20',
    CryptoJS.AES.encrypt(JSON.stringify(id), process.env.REACT_APP_ENCRYPTION_KEY).toString()
  );
};

export const toISOLocal = (d) => {
  const z = (n) => `0${n}`.slice(-2);
  return `${d.getFullYear()}-${z(d.getMonth() + 1)}-${z(d.getDate())}`;
};

// export const convertUtcToLocal = (utcTime) => {
//   // Specify the correct input format to match the timestamp format in "created_time"
//   const inputFormat = 'YYYY-MM-DD HH:mm:ss';
//   // Create a moment object with the specified format
//   const utcMoment = moment(utcTime, inputFormat);
//   // Convert the UTC time to the local time
//   const localMoment = utcMoment.local();
//   return localMoment.format('hh:mm A');
// };

// export const convertUtcToLocal = (utcTime, userTimeZone) => {
//   // Specify the input format to match your timestamp format
//   const inputFormat = 'YYYY-MM-DD HH:mm:ss';
//   // Create a moment object with the specified format and the user's time zone
//   const localMoment = moment.tz(utcTime, inputFormat, userTimeZone);
//   return localMoment.format('hh:mm A');
// };

// export const utcToLocal = (utcdateTime, tz) => {
//   const zone = moment.tz(tz).format('Z'); // Actual zone value e:g +5:00
//   const zoneValue = zone.replace(/[^0-9: ]/g, ''); // Zone value without + - chars
//   const operator = zone && zone.split('') && zone.split('')[0] === '-' ? '-' : '+'; // operator for addition subtraction
//   let localDateTime;
//   const hours = zoneValue.split(':')[0];
//   const minutes = zoneValue.split(':')[1];
//   if (operator === '-') {
//     localDateTime = moment(utcdateTime).subtract(hours, 'hours').subtract(minutes, 'minutes').format('hh:mm a');
//   } else if (operator) {
//     localDateTime = moment(utcdateTime).add(hours, 'hours').add(minutes, 'minutes').format('hh:mm a');
//   } else {
//     localDateTime = 'Invalid Timezone Operator';
//   }
//   return localDateTime;
// };

export const convertUTCToLocal = (utcDt, utcDtFormat) => {
  const toDt = moment.utc(utcDt, utcDtFormat).toDate();
  return moment(toDt).format('hh:mm a');
};

export const convertUTCToLocalDate = (utcDt, utcDtFormat) => {
  const toDt = moment.utc(utcDt, utcDtFormat).toDate();
  return moment(toDt).format('ll');
};

export const getAvailableStatuses = () => [
  { id: 1, name: 'Assigned' },
  { id: 2, name: 'Un-assigned' },
  { id: 3, name: 'Damage' },
  { id: 4, name: 'Sold' },
];
