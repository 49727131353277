export const crudConfig = {
  create: 'Successfully created.',
  add: 'Successfully added.',
  update: 'Successfully updated.',
  save: 'Successfully saved.',
  delete: 'Successfully deleted.',
  not_delete: 'Not deleted.'
};

export const userConfig = {
  create: 'User created.',
  update: 'User updated.',
  not_logout: 'Unable to logout.'
};

export const profileConfig = {
  update: 'Profile updated.'
};

export const passwordConfig = {
  set: 'Password set successfully.',
  already_set: 'Password already set successfuly!',
  not_set: 'Password could not be saved.',
  not_exist: 'Email does not exist.',
  update: 'Password changed.'
};

export const companyConfig = {
  create: 'Please create your company first.',
  register: 'Company registered.',
  not_register:
    'You cannot register your company using your current email adress. Kindly create an account with your company email.',
  not_exist: 'Please create your company first.',
  update: 'Company updated.'
};

export const projectConfig = {
  create: 'Project created.',
  update: 'Project updated.',
  delete: 'Project deleted.'
};

export const taskConfig = {
  add: 'Task added.',
  update: 'Task updated.',
  delete: 'Task deleted.',
  not_delete: 'Task could not be deleted.',
  empty_comment: 'Please type a comment first.'
};

export const kanbanConfig = {
  add: 'Column added.',
  update: 'Column updated.',
  delete: 'Column deleted.'
};

export const teamConfig = {
  invite: 'Invite sent.',
  delete: 'Employee deleted.',
  role_update: 'Role changed.',
  emp_update: 'Member updated.'
};

export const timeConfig = {
  create: 'Time created.',
  add: 'Time log added.',
  update: 'Time log updated.',
  log_delete: 'Time log deleted.',
  log_not_delete: 'Time log could not be deleted.'
};

export const eventConfig = {
  create: 'Event created.',
  update: 'Event updated.',
  delete: 'Event deleted.'
};

export const otherConfig = {
  copy: 'Copied to clipboard!',
  payment_done: 'Payment successful.',
  card_add: 'Card added.'
};

export const documentConfig = {
  add: 'Document added for all the users.',
  updated: 'Document updated.',
  delete: 'Document deleted.'
};

export const assetConfig = {
  add: 'Company asset added.',
  updated: 'Asset updated.',
  delete: 'Asset deleted.'
};

export const assetAssignToConfig = {
  add: 'Asset assign to the user.',
  updated: 'Asset updated for the user.',
  delete: 'Asset deleted for the user.'
};

export const superAdminAssignToConfig = {
  add: 'User assigned as super admin.',
  delete: 'User deleted from super admin.'
};
