import 'simplebar/src/simplebar.css';
import { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { HelmetProvider } from 'react-helmet-async';
import ThemeConfig from './theme';
import { AuthContext } from './contexts/JWTContext';
import useAuth from './hooks/useAuth';
import RtlLayout from './components/RtlLayout';
import Spinner from './components/Spinner';
import NotistackProvider from './components/NotistackProvider';
import './styles/Scrollbar.css';
import './styles/index.css';
import { getUserSession } from './utilities/utilities';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import Router from './routes';

const MAX_IDLE_TIME = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

export default function App() {
  const { isInitialized, isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const Navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    let idleTimer; // Timer to track idle time

    const handleLogout = () => {
      localStorage.clear();
      logout();
      Navigate('/');
    };

    const resetSessionTimeout = () => {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(() => {
        handleLogout();
      }, MAX_IDLE_TIME);
    };

    const onActivity = () => {
      resetSessionTimeout();
    };

    if (getUserSession() && isAuthenticated) {
      // Initialize the idle timer
      resetSessionTimeout();

      // Attach event listeners to reset the session timeout on user activity
      document.addEventListener('mousemove', onActivity);
      document.addEventListener('keydown', onActivity);
    }

    // Cleanup function
    return () => {
      clearTimeout(idleTimer);
      document.removeEventListener('mousemove', onActivity);
      document.removeEventListener('keydown', onActivity);
    };
  }, [isAuthenticated, pathname, logout, Navigate]);

  return (
    <HelmetProvider>
      <ThemeConfig>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CollapseDrawerProvider>
            <RtlLayout>
              <NotistackProvider>{isInitialized ? <Router /> : <Spinner />}</NotistackProvider>
            </RtlLayout>
          </CollapseDrawerProvider>
        </LocalizationProvider>
      </ThemeConfig>
    </HelmetProvider>
  );
}
